import React from 'react';
import Home from './components/home/Home';
import ContactUs from './components/contactUs/ContactUs';
import AboutUs from './components/aboutus/AboutUs';
import SubCategoryProduct from './category/SubCategoryProduct.js';
import Header from './common/header/Header.js';
import Footer from './common/footer/Footer.js';
import AllProductCategory from './allProductCategory/AllProductCategory.js';
import SearchResult from './searchResult/SearchResult.js';

import './App.css';

import { Routes, Route } from 'react-router-dom';

const App = () => {

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/AllProductCategory/:name/:id" element={<AllProductCategory />} />
        <Route path="/searchProduct" element={<SearchResult />} />
        <Route path="/services/:name/:id" element={
          <>
            <SubCategoryProduct />
          </>
        } />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
