import React, { useState, useContext, useEffect } from 'react';
import { SearchResultContext } from '../context/SearchResultContext';
import axios from 'axios';
import './searchresult.css';
import './mobilesearchresultresponsive.css';
import AllCategoryHeader from '../allProductCategory/AllCategoryHeader';
import Modal from 'react-bootstrap/Modal';
import send_en_img from './send_en_img.png';
import call_c from './call_c.png';
import mail_c from './mail_c.png';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { ProductContext } from '../context/ProductContext';

const SearchResult = () => {
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);

  const productsPerPage = 12;
  const { subCatProductName, setSubCatProductName } = useContext(ProductContext);
  const { searchText,searchResults, currentPage, totalPages, setSearchResults, setTotalCount, setTotalPages, setCurrentPage, setPageSize, setCounts } = useContext(SearchResultContext);

  const baseUrl = 'https://trpmanagement.s3.ap-south-1.amazonaws.com';

  const handleShow = () => setShow(true); // Function to show modal
  const handleClose = () => setShow(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchResults]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axios.get(`https://website.procureright.in/api/webproducts/filterProducts?key=${encodeURIComponent(searchText)}&page=${page}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_HANDLESEARCH}`
          }
        });

        if (res.status === 200) {
          setSearchResults(res.data.data);
          setTotalCount(res.data.totalCount);
          setTotalPages(res.data.totalPages);
          setCurrentPage(res.data.currentPage);
          setPageSize(res.data.pageSize);
          setCounts(res.data.counts);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [searchText, page]); 
  return (
    <div>
      <AllCategoryHeader />
      <div className='container'>
        <div className='row m-0 mt-5'>
          {searchResults.length > 0 ? (
            searchResults.map(product => (
              <div className='col-md-3 pb-5' key={product._id}>
                <div className='d-flex flex-column align-items-center'>
                  <div>
                    {product.images && product.images.length > 0 && (
                      <div style={{ width: "100%", height: "180px" }}>
                        <img
                          src={`${baseUrl}/${product.images[0]}`}
                          alt={product.product_name}
                          style={{ width: '100%', height: "100%", objectFit: 'cover' }}
                        />
                      </div>
                    )}
                    <div className='text-center'>
                      <p className='m-0 pt-3 pb-3 Poppins fw-bold heigt' style={{ color: "#393939" }}>{product.product_name}</p>
                      <button className='enquiry' onClick={handleShow}>Send Enquiry</button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-md-12 mt-4">
              <p>No products found.</p>
            </div>
          )}
        </div>
      </div>

      <div className='container'>
        <div className='row m-0 justify-content-center pb-3'>
          <div className='col-md-12 position-relative'>
            <PaginationControl
              page={currentPage}
              total={totalPages}
              between={4}
              limit={1}
              changePage={(page) => {
                setPage(page);
                setCurrentPage(page); // Update current page context state
              }}
              ellipsis={1}
            />
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body className='p-0'>
          <div className='row'>
            <div className='col-md-6'>
              <img src={send_en_img} className='send_en' alt='img' />
            </div>
            <div className='col-md-6'>
              <Modal.Header closeButton></Modal.Header>
              <p className='p_send_e'>Please Send Your Enquiries Via:</p>
              <div className='row mt-3'>
                <div className='col-md-2'>
                  <img src={call_c} style={{ height: '50px' }} alt='img' />
                </div>
                <div className='col-md-10'>
                  <p className='ph_ma_s'>Phone Number</p>
                  <p className='arvo fs-17 '>+91-7011558052</p>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-2'>
                  <img src={mail_c} style={{ height: '50px' }} alt='img' />
                </div>
                <div className='col-md-10'>
                  <p className='ph_ma_s'>Email Id</p>
                  <p className='r arvo fs-17'>procure@procureright.in</p>
                </div>
              </div>
              <p className='text-center arvo fs-17 fw-500 gray_text m-0'>Our team is here to assist you with any questions or concerns you may have. Feel free to reach out to us using the contact details provided above.</p>
              <p className='text-center arvo fs-17 fw-500 gray_text m-0'>or</p>
              <p className='text-center arvo fs-17 fw-500 gray_text m-0'>Click below to access our buyer portal</p>
              <div className='d-flex justify-content-center mt-3'>
                <Link to="https://buyer.procureright.in/">
                  <button className='bulk_buy'>Click here for Bulk Buy</button>
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SearchResult;
