import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CategoryContext } from '../context/CategoryContext';
import { ProductContext } from '../context/ProductContext'
import Footer from '../common/footer/Footer';
import TrpSpinner from './trp-logo.gif';
import send_en_img from './send_en_img.png';

import { Link } from "react-router-dom"
import call_c from './call_c.png';
import mail_c from './mail_c.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./subcategoryproduct.css";
import SubCatProduct from './SubCatProduct';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { NavLink, useNavigate } from 'react-router-dom';
import crpoffice from './crpoffice.png'
const SubCategoryProduct = () => {

  const navigate = useNavigate();
  const topRef = useRef(null);
  const productsRef = useRef(null)
  const BaseUrl = 'https://trpmanagement.s3.ap-south-1.amazonaws.com/';
  const { catId, currentSubSubCategoryId, setCurrentSubSubCategoryId, subCurrentPage, setSubCurrentPage } = useContext(CategoryContext);
  const { setCatId, categoryName, setCategoryName, setSubCatId, setSubSubCatId, bannerImageUrl, setBannerImageUrl } = useContext(CategoryContext);
  const { productSubcatId, subCatProductName, setProductSubcatId, setProductImageUrl, setSubCatProductName, subcategoryImage, subcategoryBannerImage, setSubcategoryImage, setSubcategoryBannerImage, subSubCategoryId, setSubSubCategoryId, } = useContext(ProductContext);
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isMinTimePassed, setIsMinTimePassed] = useState(false);
  const [subTotalCount, setSubTotalCount] = useState(0);
  const [subTotalPages, setSubTotalPages] = useState(0);
  // const [  subCurrentPage, setSubCurrentPage  ]  = useState(1);
  const [subPageSize, setSubPageSize] = useState(16);
  const [subCounts, setSubCounts] = useState(0);
  const [page, setPage] = useState(1);
  const [activeSubSubCategoryId, setActiveSubSubCategoryId] = useState(null);
  const [subSubTotalCount, setSubSubTotalCount] = useState(0);
  const [subSubTotalPages, setSubSubTotalPages] = useState(0);
  const [subSubCurrentPage, setSubSubCurrentPage] = useState(1);
  const [subSubPageSize, setSubSubPageSize] = useState(16);
  const [subSubCounts, setSubSubCounts] = useState(0);
  // const [currentSubSubCategoryId, setCurrentSubSubCategoryId] = useState(null);

  const baseUrl = 'https://trpmanagement.s3.ap-south-1.amazonaws.com';

  useEffect(() => {
    fetchSubCategories();
    fetchInitialProducts();
  }, [catId, subCurrentPage]);

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webcategories/subcategorylist?categoryId=${catId}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_PRODUCT}`
        }
      });
      if (response.data[0] && response.data[0].subWebCategories) {
        setSubCategories(response.data[0].subWebCategories);
      } else {
        setSubCategories([]);
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const fetchInitialProducts = async () => {
    setIsLoading(true);
    setIsMinTimePassed(false);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webproducts/filterProducts?categoryId=${catId}&page=${subCurrentPage}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_PRODUCT}`
        }
      });
      if (response.status === 200) {
        setProducts(response.data.data);
        setSubTotalCount(response.data.totalCount);
        setSubTotalPages(response.data.totalPages);
        setSubCurrentPage(response.data.currentPage);
        setSubPageSize(response.data.pageSize);
        setSubCounts(response.data.counts);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setTimeout(() => {
        setIsMinTimePassed(true);
      }, 1000); // Ensures the loader is displayed for at least 1 second
      setIsLoading(false);
    }
  };

  const fetchProducts = async (subSubCategoryId, page = 1) => {
    setIsLoading(true);
    setIsMinTimePassed(false);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webproducts/filterProducts?subSubCategoryId=${subSubCategoryId}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_PRODUCT}`
        }
      });
      console.log("----->", response.data)
      // console.log("subSubCategoryId------->", subSubCategoryId)
      if (response.status === 200) {
        setProducts(response.data.data);
        setSubSubTotalCount(response.data.totalCount);
        setSubSubTotalPages(response.data.totalPages);
        setSubSubCurrentPage(response.data.currentPage);
        setSubSubPageSize(response.data.pageSize);
        setSubSubCounts(response.data.counts);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setTimeout(() => {
        setIsMinTimePassed(true);
      }, 1000); // Ensures the loader is displayed for at least 1 second
      setIsLoading(false);
    }
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSubSubCategoryClick = (subSubCategoryId) => {
    setCurrentSubSubCategoryId(subSubCategoryId);
    setActiveSubSubCategoryId(subSubCategoryId);
    fetchProducts(subSubCategoryId);
    console.log(subSubCategoryId)
    // console.log("------>", activeSubSubCategoryId)
  };
  const handProductClick = () => {
    let navproid = 25.1;
    let probanner = "HAVC"
    setProductSubcatId(navproid);
    setSubSubCategoryId(null)
    setPage(1)
    setSubCatProductName(probanner);
    setSubcategoryBannerImage(crpoffice);
    navigate(`/AllProductCategory/${probanner}/${navproid}`);
  };
  const handSectorClick = () => {
    let navid = 36;
    let navname = "Hospitality"
    setCatId(36);
    setCategoryName("Hospitality");
    setBannerImageUrl(crpoffice);
    setSubCatId('');
    setSubSubCatId('');
    setCurrentSubSubCategoryId(null)
    setSubCurrentPage(1)
    navigate(`/services/${navname}/${navid}`);
  };
  const handAllClick = () => {
    let navproid = "25.1";
    let probanner = "HAVC"
    setProductSubcatId(navproid);
    setSubSubCategoryId(null)
    setPage(1)
    setSubCatProductName(probanner);
    setSubcategoryBannerImage(crpoffice);
    navigate(`/AllProductCategory/${probanner}/${navproid}`);
  };

  useEffect(() => {
    // Scroll to the top whenever the page or currentPage changes
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [subSubCurrentPage, subCurrentPage]);

  useEffect(() => {
    // Scroll to the top whenever the page or currentPage changes
    if (productsRef.current) {
      productsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [subSubCurrentPage, subCurrentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [catId]);
  return (
    <div>
     <Helmet>
        <title>TRP Managment Construction Equipment Dealers | Interior | Electrical</title>
        <meta name="description" content="Are you looking for the top construction equipment dealers specializing in both interior and electrical equipment?" />
        <meta name="keywords" content="Construction Equipment & Safety Gear, Civil & Interior, Plumbing & Sanitary, Electrical" />
      </Helmet>
      <SubCatProduct />
      <div ref={topRef}></div>
      <div className='container'>
        <div className='row pt-5 pb-5'>

          <div className='fixed_mobile_model show1'>
            <div className='container'>
              <div className='model_fidex'>
                <div>
                  <div className='text-center' onClick={(id) => { handSectorClick() }}>
                    <img src="/sectors.png"
                      alt="Sectors" />
                    <p className='mb-0 fs-16 arvo'>Sectors</p>
                  </div>
                </div>
                <div>
                  <div className='text-center'>
                    <div className='main_circle_mobile'>
                      <div className='circle_mobile'>
                        <img
                          src="/application.png"
                          alt="All"
                          onClick={handAllClick}
                        />
                      </div>
                    </div>
                    <div>
                      <p className='mb-0 fs-16 arvo all_text_mobile' onClick={handAllClick}>All</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='text-center' > <img s src="/products.png"
                    alt="Products"
                    onClick={handProductClick} />

                    <p className='mb-0 fs-16 arvo' onClick={handProductClick}>Products</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='new_aco_c hide'>
              <Accordion className="my_accordion" defaultActiveKey="0">
                {subCategories.map((subCategory, index) => (
                  <Accordion.Item eventKey={String(index)} key={subCategory._id}>
                    <Accordion.Header>{subCategory.name}</Accordion.Header>
                    <Accordion.Body>
                      {subCategory.subSubWebCategories && subCategory.subSubWebCategories.length > 0 ? (
                        <ul className="electri">
                          {subCategory.subSubWebCategories.map((subSubCategory) => (
                            <li
                              key={subSubCategory._id}
                              onClick={() => handleSubSubCategoryClick(subSubCategory._id)}
                              style={{ cursor: 'pointer' }}
                              className={subSubCategory._id === activeSubSubCategoryId ? 'active' : ''}
                            >
                              {subSubCategory.name}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <></>
                      )}
                    </Accordion.Body>

                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>

          <div className='col-md-9'>
            <div className='for_heigh_s' >
              <div ref={productsRef}></div>
              <div className='row'>
                {products.map((product) => (
                  <div className='col-6 col-md-3 col-lg-3 pb-5' key={product._id}>
                   
                    <div className='d-flex flex-column align-items-center'>
                      <div>
                        {product.images.length > 0 && (
                          <div style={{ width: "100%", height: "180px" }}>
                            <img src={`${baseUrl}/${product.images[0]}`} style={{ width: '100%', height: "100%", objectFit: 'cover' }} alt={product.product_name} className='product_mobile_images' />
                          </div>
                        )}
                        <div className='text-center'>
                          <p className='m-0 pt-3 pb-3 Poppins fw-bold heigt' style={{ color: "#393939" }}>{product.product_name}</p>
                          <button className='enquiry' onClick={handleShow}>Send Enquiry</button>
                        </div>
                      </div>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Body className='p-0'>
                          <div className='row m-0'>
                            <div className='col-md-6 p-0'>
                              <img src={send_en_img} className='send_en' alt='img' />
                            </div>
                            <div className='col-md-6 padding_descktops'>
                              <Modal.Header closeButton></Modal.Header>
                              <p className='p_send_e'>Please Send Your Enquiries Via:</p>

                              <div className='row mt-3 hide'>
                                <div className='col-md-2'>
                                  <img src={call_c} style={{ height: '50px' }} alt='img' />
                                </div>
                                <div className='col-md-10'>
                                  <p className='ph_ma_s'>Phone Number</p>
                                  <p className='poppins fs-20'>+91-7011558052</p>
                                </div>
                              </div>
                              <div className='row mb-3 hide'>
                                <div className='col-md-2'>
                                  <img src={mail_c} style={{ height: '50px' }} alt='img' />
                                </div>
                                <div className='col-md-10'>
                                  <p className='ph_ma_s'>Email Id</p>
                                  <p className='poppins fs-20'>procure@procureright.in</p>
                                </div>
                              </div>
                              <div className='show1'>
                                <div className='d-flex mt-3 gap-3'>
                                  <div className=''>
                                    <img src={call_c} style={{ height: '30px' }} alt='img' />
                                  </div>
                                  <div className=''>
                                    <p className='ph_ma_s'>Phone Number</p>
                                    <p className='poppins fs-12'>+91-7011558052</p>
                                  </div>
                                </div>
                              </div>
                              <div className='show1'>
                                <div className='d-flex mb-3 gap-3'>
                                  <div className=''>
                                    <img src={mail_c} style={{ height: '30px' }} alt='img' />
                                  </div>
                                  <div className=''>
                                    <p className='ph_ma_s'>Email Id</p>
                                    <p className='poppins fs-12'>procure@procureright.in</p>
                                  </div>
                                </div>
                              </div>

                              <p className='text-center arvo fs-16 fw-400 gray_text m-0 mobile_textssss'>Our team is here to assist you with any questions or concerns you may have. Feel free to reach out to us using the contact details provided above.</p>
                              <p className='text-center arvo fs-16 fw-400 gray_text m-0 mobile_textssss'>or</p>
                              <p className='text-center arvo fs-16 fw-400 gray_text m-0 mobile_textssss'>Click below to access our buyer portal</p>
                              <div className='d-flex justify-content-center mt-3 mobile_paddings2'>
                                <Link to="https://buyer.procureright.in/">
                                  <button className='bulk_buy arvo'>Click here for bulk Buy</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>

      </div>

      {/* Pagination section */}
      <div className='container'>
        <div className='row m-0 justify-content-center pb-3'>
          <div className='col-10 col-md-12 position-relative mobile_responsiver_pagination'>
            {currentSubSubCategoryId !== null ? (
              <PaginationControl
                page={subSubCurrentPage}
                total={subSubTotalPages}
                limit={1}
                changePage={(page) => {
                  fetchProducts(currentSubSubCategoryId, page);
                  setSubSubCurrentPage(page);
                }}
                ellipsis={1}
              />
            ) : (
              <PaginationControl
                page={subCurrentPage}
                total={subTotalPages}
                limit={1}
                changePage={(page) => {
                  setSubCurrentPage(page);
                }}
                ellipsis={1}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryProduct;
