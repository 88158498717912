import React from 'react';
import { useState, useEffect, useContext } from 'react';
import Footer from '../../common/footer/Footer'
import Header from '../../common/header/Header'
import { Helmet } from 'react-helmet';
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./mobilehomeresponsive.css";
import { Link } from "react-router-dom"
import Carousel from 'react-bootstrap/Carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import AiWep from './ai.webp'
import Const from './const.webp'
import { CategoryContext } from '../../context/CategoryContext';
import { ProductContext } from '../../context/ProductContext';
import Hospitality from './home1.webp';
import Educational from './home12.png'
import Industrial from './home3.webp'
import Commercial from './home4.webp'
import Data_Centers from './home5.webp'
import Warehouse from './home6.webp'
import Hospitals from './home7.webp'
import Residential from './home8.webp'
import Corporate_Office from './home9.webp'
import Co_working_Space from './home10.webp'


let images = [Hospitality, Educational, Industrial, Commercial, Data_Centers, Warehouse, Hospitals, Residential, Corporate_Office, Co_working_Space]

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow next" onClick={onClick}>
      &gt;
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow prev" onClick={onClick}>
      &lt;
    </div>
  );
};

const Home = () => {

  const BaseUrl = 'https://trpmanagement.s3.ap-south-1.amazonaws.com/';
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [proSubCategories, setProSubCategories] = useState([]);

  const { catId, setCatId, categoryName, setCategoryName, setSubCatId, setSubSubCatId, bannerImageUrl, setBannerImageUrl, setCurrentSubSubCategoryId, setSubCurrentPage } = useContext(CategoryContext);
  const { productSubcatId, subCatProductName, setProductSubcatId, setProductImageUrl, setSubCatProductName, subcategoryImage, subcategoryBannerImage, setSubcategoryImage, setSubcategoryBannerImage, subSubCategoryId, setSubSubCategoryId, page, setPage } = useContext(ProductContext);
  const [isDivVisible, setDivVisible] = useState(false);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://website.procureright.in/api/webcategories/all', {
          headers: {
            // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiIxMTI2IiwiaWF0IjoxNjkyNzczMjU3fQ.QQhOYSAfbbhMSt1RRXa3bm_HzucnEBeVPsbpa_F8yug'
          }
        });
        console.log(response.data)
        setCategories(response.data);

      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webcategories/subcategorylist?categoryId=25`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_CATEGORY}`
          }
        });
        console.log(response.data[0].subWebCategories)
        setSubCategories(response.data[0].subWebCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubproduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webcategories/subcategorylist?categoryId=25`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_CATEGORY}`
          }
        });
        setProSubCategories(response.data[0].subWebCategories);

      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchSubproduct();
  }, []);

  const toggleDiv = () => {
    setDivVisible(!isDivVisible);
  };
  const handleCategoryClick = (id, name, bannerImage) => {
    setCatId(id);
    setCategoryName(name);
    setBannerImageUrl(BaseUrl + bannerImage);
    setSubCatId('');
    setSubSubCatId('');
    setCurrentSubSubCategoryId(null)
    setSubCurrentPage(1)
  };
  const producthandleCategoryClick = (id, name, bannerImage, image) => {
    setProductSubcatId(id);
    setSubSubCategoryId(null)
    setPage(1)
    setSubCatProductName(name);
    setSubcategoryBannerImage(BaseUrl + bannerImage);
    setSubcategoryImage(BaseUrl + image);
  };


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />
  };

  const [activeTab, setActiveTab] = useState('tab1');
  return (
    <>
      <Helmet>
        <title>TRP Managment</title>
        <meta name="description" content="TRP Building and Construction Materials Supplier is a leading provider of high-quality materials for the construction industry." />
        <meta name="keywords" content="Construction Materials Supplier, Construction Materials, Materials Supplier" />
      </Helmet>
      <div className='hide'>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/background3.png"
              alt="First slide"
            />
            <div className='main_carasoule'>
              <div className='container'>
                <div className='row m-0 align-items-center'>
                  <div className='col-md-12 new_cls'>
                    <img src="./halfdiv.webp" style={{ width: '21%' }} />
                    <div className=''>
                      <div className='text_heading'>
                        <h2 className='fs-25 fw-400 text-white arvo' style={{ width: "75%" }}>At TRP , we specialize in providing construction, industrial, and engineering materials that turn dreams into reality. </h2>
                        <p className='fs-15 fw-500 text-white poppins mt-2' style={{ lineHeight: 1.4, width: "75%" }}>From the initial foundation to the final finishing touches, we're dedicated to elevating construction standards with unwavering quality and precision. Across every sector and every project, we're committed to delivering excellence, every time.</p>
                        <div className='pt-5'>
                          <Link to="/AboutUs">
                            <button className="navbar_Btn cp bg-orange arvo">Know More</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/background4.webp"
              alt="First slide" />
            <div className='main_carasoule'>
              <div className='container'>
                <div className='row m-0 align-items-center'>
                  <div className='col-md-12'>
                    <img src="./halfdiv.webp" style={{ width: '21%' }} />
                    <div className=''>
                      <div className='text_heading'>
                        <h2 className='fs-25 fw-500 arvo' style={{ width: "70%" }}>TRP: Your Trusted Partner for Sourcing Construction, Industrial, and Engineering Materials and Equipment.</h2>
                        <p className='fs-15 fw-400 poppins mt-2' style={{ lineHeight: 1.4, width: "70%" }}>Streamline your procurement process with our vast network of vendors and extensive product range with a simple click. Our B2B procurement platform seamlessly connects buyers and sellers, providing real-time, accurate information.</p>
                        <div className='pt-4'>
                          <Link to="/AboutUs">
                            <button className="navbar_Btn cp bg-orange arvo">Know More</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

        </Carousel>
      </div>

      <div className='show1'>
        {/* <div> */}
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 mobile_home_banner"
              src="/background6.png"
              alt="First slide"
            />
            <div className='main_carasoule'>
              <div className='container'>
                <div className='row m-0'>
                  <div className='col-md-12'>
                    <h2 className='fs-25 fw-400 arvo para_mobile3'>At TRP , we specialize in providing construction, industrial, and engineering materials that turn dreams into reality. </h2>
                  </div>
                  <div className='col-md-12'>
                    <p className='fs-15 fw-400 poppins mt-2 text_align_mobile2' style={{ lineHeight: 1.4 }}>From the initial foundation to the final finishing touches, we're dedicated to elevating construction standards with unwavering quality and precision. Across every sector and every project, we're committed to delivering excellence, every time.</p>
                  </div>
                  <div className='pt-3'>
                    <Link to="/AboutUs">
                      <button className="navbar_Btn cp bg-orange arvo">Know More</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 mobile_home_banner"
              src="/background5.webp"
              alt="First slide" />
            <div className='main_carasoule'>
              <div className='container'>
                <div className='row m-0'>
                  <div className='col-md-12'>
                    <h2 className='fs-25 fw-500 arvo para_mobile3'>TRP: Your Trusted Partner for Sourcing Construction, Industrial, and Engineering Materials and Equipment.</h2>
                  </div>
                  <div className='col-md-12'>
                    <p className='fs-15 fw-400 poppins mt-2 text_align_mobile2' style={{ lineHeight: 1.4 }}>Streamline your procurement process with our vast network of vendors and extensive product range with a simple click. Our B2B procurement platform seamlessly connects buyers and sellers, providing real-time, accurate information.</p>
                  </div>
                  <div className='pt-3'>
                    <Link to="/AboutUs">
                      <button className="navbar_Btn cp bg-orange arvo">Know More</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

        </Carousel>
        {/* </div> */}
      </div>

      <div>
        <div style={{ width: '100%', height: 'auto' }}>
          <div className='bg-orange'>
            <div className='container p-0 hide'>
              <div className="row m-0 justify-content-center align-items-center text-center pt-4 pb-4">
                <div className="col-md-3">
                  <div>
                    <p className="fw-800 fs-30 mb-0 arvo">11000</p>
                    <h3 className="fw-400 fs-18 arvo">Products in Our Library</h3>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <p className="fw-800 fs-30 mb-0 arvo">1887</p>
                    <h3 className="fw-400 fs-18 arvo">Projects Estimated</h3>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <p className="fw-800 fs-30 mb-0 arvo">3058</p>
                    <h3 className="fw-400 fs-18 arvo">Pan India Seller</h3>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <p className="fw-800 fs-30 mb-0 arvo">150</p>
                    <h3 className="fw-400 fs-18 arvo">Locations</h3>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className='container p-0 show1'>
              <div className="row m-0 justify-content-center align-items-center text-center pt-4 pb-4 p-2">
                <div className='d-flex gap-4'>
                  <div className='col-md-6'>
                    <div className='text-start'>
                      <p className="fw-800 fs-30 mb-0 arvo home_num_mobile">11000</p>
                      <h3 className="fw-400 fs-18 arvo arvo-regular4">Products in Our Library</h3>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='text-start'>
                      <p className="fw-800 fs-30 mb-0 arvo home_num_mobile">1887</p>
                      <h3 className="fw-400 fs-18 arvo arvo-regular4">Projects Estimated</h3>
                    </div>
                  </div>
                </div>
                <div className='d-flex gap-5'>
                  <div className='col-md-6'>
                    <div className='text-start'>
                      <p className="fw-800 fs-30 mb-0 arvo home_num_mobile">3058</p>
                      <h3 className="fw-400 fs-18 arvo arvo-regular4">Pan India Seller</h3>
                    </div>
                  </div>
                  <div className='col-md-6' style={{ paddingLeft: "32px" }}>
                    <div className='text-start'>
                      <p className="fw-800 fs-30 mb-0 arvo home_num_mobile">150</p>
                      <h3 className="fw-400 fs-18 arvo arvo-regular4">Locations</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=''>
          <div className='container'>
            <div className="row m-0 justify-content-center pt-5 pb-5">
              <p className="text-center fs-18 fw-400 poppins arvo-regular2 margin_mobile">PRODUCT PORTFOLIO</p>
              <div className="col-md-11 text-center pb-5">
                <h2 className="text-center fs-30 fw-400 arvo para_mobile3">
                  Discover our vast range of <span className="text-orange fs-30 fw-700 para_mobile3">quality construction materials</span>, tailored to meet all your project needs.
                </h2>
              </div>

              <div className="col-md-11 pt-5 hide">
                <div style={{ position: 'relative' }}>
                  <div className="border_circle"></div>
                  <div className='main_divs2'>
                    <div className='divss'>
                      {proSubCategories.slice(0, 5).map((subCategory, index) => (
                        subCategory.name !== 'All Products' && (
                          <div className='box-container' key={subCategory._id}>
                            <Link
                              to={`/AllProductCategory/${subCategory.name}/${subCategory._id}`}
                              style={{ textDecoration: 'none' }}
                              onClick={() => producthandleCategoryClick(subCategory._id, subCategory.name, subCategory.bannerImage, subCategory.image)}
                            >
                              <div className='boxStyle1'>
                                <div className='d-flex justify-content-center'>
                                  <div className='back_img'>
                                    <img src={BaseUrl + subCategory.image} alt={subCategory.name} style={{ width: '60%' }} />
                                  </div>
                                </div>
                                <div>
                                  <h3 className='fs-14 fw-400 arvo pt-1 text-black'>{subCategory.name}</h3>
                                </div>
                              </div>
                            </Link>
                          </div>
                        )
                      ))}
                    </div>
                  </div>


                  <div className='main_divs'>
                    <div className='divss2'>
                      {proSubCategories.slice(5).map((subCategory, index) => (
                        subCategory.name !== 'All Products' && (
                          <div className='' key={subCategory._id}>

                            <Link
                              to={`/AllProductCategory/${subCategory.name}/${subCategory._id}`}
                              style={{ textDecoration: 'none' }}
                              onClick={() => producthandleCategoryClick(subCategory._id, subCategory.name, subCategory.bannerImage, subCategory.image)}
                            >
                              <div className='boxStyle1'>
                                <div className='d-flex justify-content-center'>
                                  <div className='back_img'>
                                    <img src={BaseUrl + subCategory.image} alt={subCategory.name} style={{ width: '60%' }} />
                                  </div>
                                </div>
                                <div>
                                  <h3 className='fs-14 fw-400 arvo pt-1 text-black'>{subCategory.name}</h3>
                                </div>
                              </div>
                            </Link>

                          </div>
                        )
                      ))}
                    </div>
                  </div>
                </div>








              </div>

              <div style={{ position: 'relative' }}>
                <div className='col-md-11 show1'>
                  <Slider {...settings}>
                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'>
                        <img src="/first.png" style={{ width: '75%' }} alt="Civil & Interiors" /></div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2'> Civil & <br /> Interiors </h3></div>
                    </div>
                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'>
                        <img src="/eigth.png" style={{ width: '75%' }} alt="Electrical" /></div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2'> Electrical </h3></div>
                    </div>
                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'>
                        <img src="/fan.png" style={{ width: '75%' }} alt="HVAC" /></div>
                      <div><h3 className='fs-11 fw-400 pt-2 arvo'> HVAC </h3></div>
                    </div>
                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'>
                        <img src="/seventh.png" style={{ width: '75%' }} alt="Plumbing & Sanitary" /></div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2'> Plumbing & <br /> Sanitary </h3></div>
                    </div>
                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'>
                        <img src="/sixth.png" style={{ width: '75%' }} alt="Water Treatment" /></div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2'> Water <br /> Treatment </h3></div>
                    </div>

                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'>
                        <img src="/fifth.png" style={{ width: '75%' }} alt="Fire Fighting" />
                      </div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2'> Fire Fighting </h3></div>
                    </div>

                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'><img src="/fourth.png" style={{ width: '75%' }} alt="Water Distribution" /></div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2'> Water <br /> Distribution </h3></div>
                    </div>

                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'><img src="/third.png" style={{ width: '75%' }} alt="Boiler & Steam" /></div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2'> Boiler & Steam </h3></div>
                    </div>

                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'><img src="/second.png" style={{ width: '75%' }} alt="Construction equipment & Safety Gear" /></div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2' style={{ lineHeight: "12px" }}> Construction<br />equipment &<br />Safety Gear </h3></div>
                    </div>

                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'><img src="/thenth.png" style={{ width: '75%' }} alt="HSD System" /></div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2'> HSD System </h3></div>
                    </div>
                    <div className='mobile_boxStyle1'>
                      <div className='d-flex justify-content-center'><img src="/ninth.png" style={{ width: '75%' }} alt="Compressed Air System" /></div>
                      <div><h3 className='fs-11 fw-400 arvo pt-2'> Compressed <br /> Air System </h3></div>
                    </div>
                  </Slider>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="row m-0 justify-content-center pt-5 pb-5">
          <div className='col-md-12 desk-top'>
            <p className="text-center fs-18 fw-400 poppins arvo-regular2 margin_mobile pt-5 para_mobile4">ABOUT US</p>
            <h2 className="text-center fs-30 fw-400 arvo pb-3 para_mobile3">TRP: Your <span className="text-orange fw-700 fs-30 para_mobile3">One-Stop Solution</span> for Procurement Excellence</h2>
          </div>

          <div className="col-md-11 text-center pb-4 d-flex justify-content-center align-items-center text_aligment_mobile">
            <div className='container d-flex justify-content-center'>
              <p className="fs-16 fw-400 poppins text_fs15_mobile" style={{ width: "86%" }}>
                At TRP, we streamline the procurement process, making it efficient. Our platform connects buyers with a vast array of products, ensuring that you always find the right materials at competitive prices. For sellers, we provide access to a wide market, helping you reach new customers and expand your business.</p>
            </div>
          </div>
          <div className='show1'>
            <div className='pt-2 pb-5 d-flex justify-content-center'>
              <Link to="/AboutUs">
                <button className="navbar_Btn cp bg-orange arvo">Know More</button>
              </Link>
            </div>
          </div>


          <div className='container'>
            <div className="row m-0 justify-content-center hide">
              <div className="col-md-11 text-center pt-4 pb-4" style={{ backgroundColor: '#ffd798', borderRadius: '20px' }}>
                <div className="row m-0">
                  <div className="col-md-3" style={{ position: 'relative' }}>
                    <div style={{ position: 'relative' }}>
                      <div className="details_text_box mt-3">
                        <img src="/group4.png" alt="EPC Companies" style={{ width: '20%' }} />
                        <div className="text-start arvo">EPC Companies</div>
                      </div>
                      <div className="details_text_box">
                        <img src="/group3.png" alt="Manufacturing Companies" style={{ width: '20%' }} />
                        <div className="text-start arvo">Manufacturing <br /> Companies</div>
                      </div>
                      <div className="details_text_box">
                        <img src="/griup2.png" alt="Contractors" style={{ width: '20%' }} />
                        <div className="text-start arvo">Contractors</div>
                      </div>
                      <div className="details_text_box">
                        <img src="/group.png" alt="End Users" style={{ width: '20%' }} />
                        <div className="text-start arvo">End Users</div>
                      </div>
                      <div className='small_underline'>
                        <div className='underline_parent'>
                          <div className="underline"></div>
                          <div className="underline"></div>
                          <div className="underline"></div>
                          <div className="underline"></div>
                        </div>
                        <div className='underline_align'>
                          <div className="underline2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 main_div">
                    <div style={{ position: "relative" }}>
                      <div className='underline3'></div>
                      <div className='absolute_div'>
                        <div className='container'>
                          <div className='row m-0 justify-content-center'>
                            <div className='main_div'>
                              <div className='col-md-4 d-flex justify-content-center'>
                                <div className='new_div'>
                                  <div className='buyer_text'>
                                    <Link to="https://buyer.procureright.in/" style={{ textDecoration: "none" }}>
                                      <p className='poppins' style={{ color: "#000" }}>Buyer</p>
                                    </Link>
                                  </div>
                                  <div className='new_div_child'>
                                    <img src="/desc_seller.png" alt='person' className='sb_images' />
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 d-flex justify-content-center'>
                                <div style={{ position: "relative" }} className='hide'>
                                  <div className='rest'>
                                    <div className='circle'></div>
                                    <div className='images_rest'>
                                      <img src="/logohasmukh.png" alt='person' style={{ width: "100%" }} />
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className='col-md-4 d-flex justify-content-center'>
                                <div className='new_div'>
                                  <div className='buyer_text'>
                                    <Link to="https://seller.procureright.in/" style={{ textDecoration: "none" }}>
                                      <p className='poppins' style={{ color: "#000" }}>Seller</p>
                                    </Link>
                                  </div>
                                  <div className='new_div_child2'>
                                    <img src="/desc_buyer.png" alt='person' className='sb_images' />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-3" style={{ position: 'relative' }}>
                    <div style={{ position: 'relative' }}>
                      <div className="details_text_box mt-3">
                        <img src="/group7.png" alt="EPC Companies" style={{ width: '20%' }} />
                        <div className="text-start arvo">MSME Manufacturers</div>
                      </div>
                      <div className="details_text_box">
                        <img src="/group8.png" alt="Manufacturing Companies" style={{ width: '20%' }} />
                        <div className="text-start arvo">Distributers & <br /> wholesalers</div>
                      </div>
                      <div className="details_text_box">
                        <img src="/group9.png" alt="Contractors" style={{ width: '20%' }} />
                        <div className="text-start arvo">OEM Companies</div>
                      </div>
                      <div className="details_text_box">
                        <img src="/group10.png" alt="End Users" style={{ width: '20%' }} />
                        <div className="text-start arvo">Traders</div>
                      </div>
                      <div className='small_underline2'>
                        <div className='underline_align'>
                          <div className="underline2"></div>
                        </div>
                        <div className='underline_parent'>
                          <div className="underline"></div>
                          <div className="underline"></div>
                          <div className="underline"></div>
                          <div className="underline"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-0 justify-content-center show1">
              <div className="col-md-12 text-center pt-5 pb-5" style={{ backgroundColor: '#ffd798', borderRadius: '20px' }}>

                <div style={{ width: "100%", height: "100%" }}>
                  <div className='rowss'>
                    <div className='line_under'>
                    </div>
                    <div className='rowsss'>
                      <div className="row m-0 justify-content-center">
                        <div className='col-md-12'>
                          <div className='d-flex justify-content-center'>
                            <div className="details_text_box">
                              <img src="/group4.png" alt="EPC Companies" style={{ width: '20%' }} className="mobile_imsge" />
                              <div className="text-start arvo">EPC Companies</div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center'>
                            <div className="details_text_box">
                              <img src="/group3.png" alt="Manufacturing Companies" style={{ width: '20%' }} className="mobile_imsge" />
                              <div className="text-start arvo">Manufacturing <br /> Companies</div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center'>
                            <div className="details_text_box">
                              <img src="/griup2.png" alt="Contractors" style={{ width: '20%' }} className="mobile_imsge" />
                              <div className="text-start arvo">Contractors</div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center'>
                            <div className="details_text_box">
                              <img src="/group.png" alt="End Users" style={{ width: '20%' }} className="mobile_imsge" />
                              <div className="text-start arvo">End Users</div>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-12 pt-5 pb-5'>
                          <div className='d-flex justify-content-center'>
                            <div className='new_div'>
                              <div className='buyer_text'>
                                <p className='poppins'>Buyer</p>
                              </div>
                              <div className='new_div_child'>
                                <img src="/desc_seller.png" alt="person" class="sb_images" />
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center pt-4 pb-5'>

                            <div style={{ position: "relative" }} className=''>
                              <div className=''>
                                <div className='circle'></div>
                                <div className='images_rest'>
                                  <img src="/logohasmukh.png" alt='person' style={{ width: "100%" }} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center'>
                            <div className='new_div'>
                              <div className='buyer_text'>
                                <p className='poppins'>Seller</p>
                              </div>
                              <div className='new_div_child2'>
                                <img src="/desc_buyer.png" alt='person' className='sb_images' />

                                {/* <img src="/person.png" alt='person' /> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-12'>
                          <div className='d-flex justify-content-center'>
                            <div className="details_text_box">
                              <img src="/group7.png" alt="EPC Companies" style={{ width: '20%' }} className="mobile_imsge" />
                              <div className="text-start arvo">MSME Manufacturers</div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center'>
                            <div className="details_text_box">
                              <img src="/group8.png" alt="Manufacturing Companies" style={{ width: '20%' }} className="mobile_imsge" />
                              <div className="text-start arvo">Distributers & <br /> wholesalers</div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center'>
                            <div className="details_text_box">
                              <img src="/group9.png" alt="Contractors" style={{ width: '20%' }} className="mobile_imsge" />
                              <div className="text-start arvo">OEM Companies</div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center'>
                            <div className="details_text_box">
                              <img src="/group10.png" alt="End Users" style={{ width: '20%' }} className="mobile_imsge" />
                              <div className="text-start arvo">Traders</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='button_div pt-5 hide'>
            <Link to="/AboutUs">
              <button className='navbar_Btn cp bg-orange arvo'>Know More</button>
            </Link>
          </div>

          <div className='container'>
            <div className="row m-0 justify-content-center pt-5">
              <div className='col-md-12'>
                <p className="text-center fs-18 fw-400 poppins arvo-regular2 margin_mobile hide">WHY DO YOU NEED US</p>
                <p className="text-center fs-18 fw-400 poppins arvo-regular2 margin_mobile show1">WHY CHOOSE US</p>
              </div>
              <div className='col-md-10 d-flex justify-content-center'>
                <h2 className="text-center fs-30 fw-400 arvo para_mobile3 hide">Discover the essential reasons <span className="text-orange fs-30 fw-700 para_mobile3"> why TRP </span>is indispensable for your bussiness.</h2>
                <h2 className="text-center fs-30 fw-400 arvo para_mobile3 show1">Discover why TRP is the  <span className="text-orange fs-30 fw-700 para_mobile3"> one stop solution for our clients </span>for a  Seamless Procurement Solutions.</h2>
              </div>
            </div>
          </div>

          <div className='row m-0 p-0'>
            <div className='col-md-12 p-0 pt-4'>
              {activeTab === 'tab1' && (
                <div className="tab-content">
                  <div class='row m-0 justify-content-center pt-3 tabs_section'>
                    <div className='tabs pb-4'>
                      <div className='buy_vende_btn'>
                        <div>
                          <button
                            className={`tab_btns ${activeTab === 'tab1' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab1')}
                          >
                            Buyer
                          </button>
                        </div>
                        <div>
                          <button
                            className={`tab_btns ${activeTab === 'tab2' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab2')}
                          >
                            Seller
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-10 d-flex justify-content-center pb-4'>
                      <ul class="nav nav-pills ul_tab hide" id="pills-tab" role="tablist" style={{ position: "relative" }}>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">EPC Companies</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Manufacturing Companies</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Contractors</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-endusers-tab" data-bs-toggle="pill" data-bs-target="#pills-endusers" type="button" role="tab" aria-controls="pills-endusers" aria-selected="false">End Users</button>
                        </li>
                        <div className='paddings_align'>
                          <div className='tab_underline'></div>
                        </div>
                      </ul>
                      <div class="container show1">
                        <div class="row">
                          <div class="col padding_align_home">
                            <ul class="nav nav-pills ul_tab d-flex flex-nowrap" id="pills-tab" role="tablist">
                              <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">EPC <br></br> Companies </button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Manufacturing <br></br> Companies</button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" style={{ height: "62px" }}>Contractors</button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-endusers-tab" data-bs-toggle="pill" data-bs-target="#pills-endusers" type="button" role="tab" aria-controls="pills-endusers" aria-selected="false" style={{ height: "62px" }}>End Users</button>
                              </li>
                              <div className='paddings_align'>
                                <div className='tab_underline'></div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                      <div className='container'>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center'>
                            <img src='./tab1.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start justify-content-center padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Complex Supply chain</h2>
                            </div>
                            <div className='tab_mobile_text'>
                              <div className='d-flex flex-column w-100'>
                                <div className='d-flex gap-3'>
                                  <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                  <div>
                                    <p className='fs-16 fw-400 poppins tab_para_mobile' style={{ width: "492px" }} ><span className="fs-16 fw-800 poppins" >Challenge: </span> EPC projects usually involve many different suppliers and subcontractors, which can make managing the supply chain complicated. It's challenging to coordinate and oversee all these different groups.</p>
                                  </div>
                                </div>
                                <div className='d-flex gap-3'>
                                  <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} /></div>
                                  <div>
                                    <p className='fs-16 fw-400 poppins tab_para_mobile' style={{ width: '488px' }}><span className="fs-16 fw-800 poppins">TRP Solution: </span> TRP simplifies this process by providing a centralized platform to efficiently coordinate and carryout all procurement activities. With TRP, you can ensure seamless communication, streamlined operations, and effective collaboration among all parties, enhancing project efficiency and reducing logistical challenges.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0 justify-content-center pt-5 about_trp_img_mobile'>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Vendor Pricing Discrepancies</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile' style={{ width: '450px' }}><span className="fs-16 fw-800 poppins" >  Challenge: </span>Negotiating costs becomes challenging when there are discrepancies in pricing among different vendors for similar materials or services.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile' style={{ width: '444px' }}><span className="fs-16 fw-800 poppins">TRP Solution: </span> TRP's extensive market reach and direct access to multiple suppliers enable EPC companies to conduct price benchmarking effectively.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab2.png' className='img_fluid' alt='tab1 image' />
                          </div>

                        </div>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile padding_align_home'>
                            <img src='./tab3.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Technology Integration</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile' style={{ width: '503px' }}><span className="fs-16 fw-800 poppins">Challenge: </span> Incorporating procurement management software and digital platforms requires investment and training but can enhance efficiency and transparency.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile' style={{ width: '523px' }}><span className="fs-16 fw-800 poppins">TRP Solution: </span> The TRP technology platform, ProcureRight, enables EPC companies to effectively monitor their ongoing inquiries, access historical procurement data, and manage estimations.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                      <div className='container'>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center padding_align_home'>
                            <img src='./tab4.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Frequent Creation of Vendor Codes</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins">Challenge: </span> Manufacturing companies face the challenge of creating new vendor codes for every purchase, leading to increased administrative burden and complexity. This process is time-consuming and inefficient.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins">TRP Solution: </span> Onboarding TRP, you gain indirect access to a comprehensive network of over 3000 pre-vetted vendors, all conveniently gathered in one location. This centralized platform caters to your diverse requirements, ultimately saving you valuable time and effort in your procurement process.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0 justify-content-center pt-5 about_trp_img_mobile'>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Streamlining Utility Procurements</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins">Challenge: </span> Manufacturing companies invest in both Capital Expenditure (CapEx) and Operational Expenditure (OpEx) to meet their plant requirements, which may involve purchasing new materials or upgrading existing technology.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins">TRP Solution: </span> TRP offers assistance in procuring all necessary materials for utility needs.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab5.png' className='img_fluid' alt='tab1 image' />
                          </div>
                        </div>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab6.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Optimizing Procurement Amid MOQ Challenges</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins">Challenge: </span> Suppliers often expect MOQs (Minimum Order Quantities) against client requirements. However, these requirements may not align with the buyer's needs, leading to excess inventory or higher costs for smaller orders.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins">TRP Solution: </span> TRP’s platform simplifies vendor management, allowing you to efficiently handle all transactions and relationships from one central hub.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                      <div className='container'>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center'>
                            <img src='./tab7.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Digitization Challenges in Construction Procurement</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-18 fw-400 poppins tab_para_mobile'> <span className="fs-16 poppins fw-800"> Challenge: </span> In the construction industry, many contractors still rely on traditional manual procurement methods, such as sending RFQs via platforms like WhatsApp or email and manually tracking follow-ups. This repetitive process lacks the benefits of historical purchase data, hindering efficiency and productivity.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> The TRP technology platform, ProcureRight, enables clients to centralize their procurements, including handling estimates, purchase requisitions, and more, all within one user-friendly platform.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0 justify-content-center pt-5 about_trp_img_mobile'>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Geographical Constraints</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-18 poppins fw-400 tab_para_mobile'> <span className="fs-16 poppins fw-800"> Challenge: </span> Sourcing materials across different regions can be difficult and time-consuming </p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP supplies materials on a pan-India basis, ensuring you can access the products you need, regardless of your location.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab8.png' className='img_fluid' alt='tab1 image' />
                          </div>
                        </div>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab9.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>High Costs of Specialized Resources</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-18 poppins fw-400 tab_para_mobile'> <span className="fs-16 poppins fw-800"> Challenge: </span> Employing specialized procurement resources can be expensive for MSME contractors.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> With TRP managing procurement, clients can streamline the process by sharing their project BOQs. TRP takes over from there, reducing overhead and ensuring efficient purchasing.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-endusers" role="tabpanel" aria-labelledby="pills-endusers-tab">
                      <div className='container'>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center'>
                            <img src='./tab10.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Lack of Product Understanding</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800 poppins"> Challenge: </span>End users often struggle with understanding the specifications and suitability of various construction materials.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span>TRP offers comprehensive product specifications for every inquiry, empowering end users to make informed decisions and select the ideal materials for their requirements.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0 justify-content-center pt-5 about_trp_img_mobile'>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Limited Market Reach</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800"> Challenge: </span> Conducting market research to find the best products and prices can be time-consuming and overwhelming for clients not related to construction industry</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP simplifies this process by offering a wide range of products from trusted vendors, all in one place, making it easy to compare and choose the best options.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab11.png' className='img_fluid' alt='tab1 image' />
                          </div>
                        </div>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab12.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>No Cost Benchmarking</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800 poppins"> Challenge: </span> Without cost benchmarks, end users may overpay for materials or struggle to stay within budget.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP provides competitive pricing and cost benchmarks, helping you stay within budget and get the best value for your money.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'tab2' && (
                <div className="tab-content">
                  <div class='row m-0 justify-content-center pt-3 tabs_section'>
                    <div className='tabs pb-4'>
                      <div className='buy_vende_btn'>
                        <div>
                          <button
                            className={`tab_btns ${activeTab === 'tab1' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab1')}
                          >
                            Buyer
                          </button>
                        </div>
                        <div>
                          <button
                            className={`tab_btns ${activeTab === 'tab2' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab2')}
                          >
                            Seller
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-10 d-flex justify-content-center pb-4'>
                      <ul class="nav nav-pills ul_tab hide" id="pills-tab" role="tablist" style={{ position: "relative" }}>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">MSME Manufacturers</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Distributers & Wholesalers</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">OEM Companies</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="pills-endusers-tab" data-bs-toggle="pill" data-bs-target="#pills-endusers" type="button" role="tab" aria-controls="pills-endusers" aria-selected="false">Traders</button>
                        </li>
                        <div className='paddings_align'>
                          <div className='tab_underline2'></div>
                        </div>
                      </ul>

                      <div class="container show1">
                        <div class="row">
                          <div class="col padding_align_home">
                            <ul class="nav nav-pills ul_tab d-flex flex-nowrap" id="pills-tab" role="tablist">
                              <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">MSME <br></br> Manufacturers</button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Distributers <br></br> & Wholesalers</button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">OEM <br></br> Companies</button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-endusers-tab" data-bs-toggle="pill" data-bs-target="#pills-endusers" type="button" role="tab" aria-controls="pills-endusers" aria-selected="false" style={{ height: "62px" }}>Traders</button>
                              </li>
                              <div className='paddings_align'>
                                <div className='tab_underline2'></div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                      <div className='container'>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center'>
                            <img src='./tab13.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Limited Sales Team</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800"> Challenge: </span> MSME manufacturers often have small sales teams, making it difficult to reach a broad customer base and generate significant sales.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP connects you with a vast network of buyers, expanding your reach and driving sales without the need for a large sales team.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='row m-0 justify-content-center pt-5 about_trp_img_mobile'>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Limited  Brand Visibility</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800 poppins"> Challenge: </span> Building brand visibility in a competitive market can be challenging for MSME manufacturers with limited resources.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP provides a platform to present your products to a wider audience, enhancing brand visibility and helping you stand out in the market.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab14.png' className='img_fluid' alt='tab1 image' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                      <div className='container'>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center'>
                            <img src='./tab15.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Geographical Constraints</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800 poppins"> Challenge: </span> Distributors and wholesalers often face limitations in expanding their market reach due to geographical constraints.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP offers a pan-India platform, enabling you to reach customers across the country and expand your market without geographical limitations.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0 justify-content-center pt-5 about_trp_img_mobile'>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Streamlining Utility Procurements</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800 poppins"> Challenge: </span> Managing a complex supply chain can lead to inefficiencies and increased operational costs.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP streamlines the supply chain process by connecting you with a wide network of buyers and suppliers, ensuring smooth and efficient operations.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab16.png' className='img_fluid' alt='tab1 image' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                      <div className='container'>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center'>
                            <img src='./tab17.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Focus on Key Accounts</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800 poppins"> Challenge: </span> OEM companies face challenges in directly reaching all potential accounts, leaving a significant portion of the market untapped.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP aggregates requirements from multiple buyers and presents these inquiries to OEMs, thereby becoming a key partner for OEM companies.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-endusers" role="tabpanel" aria-labelledby="pills-endusers-tab">
                      <div className='container'>
                        <div className='row m-0 justify-content-center pt-5 reverse about_trp_img_mobile'>
                          <div className='col-md-5 text-center'>
                            <img src='./tab18.png' className='img_fluid' alt='tab1 image' />
                          </div>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Limited Understanding of Complex Material Specifications</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800 poppins"> Challenge: </span> Traders often struggle with understanding the intricate details and specifications of various construction and industrial materials, which can lead to incorrect purchasing decisions.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP provides comprehensive product information and expert guidance, ensuring you have a clear understanding of material specifications and helping you make informed purchasing decisions.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0 justify-content-center pt-5 about_trp_img_mobile'>
                          <div className='col-md-5 text-start d-flex align-items-center justify-content-center pt-5 about_trp_img_mobile padding_align_home'>
                            <div>
                              <h2 className='fs-25 fw-700 pb-3 arvo arvo-regular5 padding_mobile_margin'>Supplier Reliability</h2>
                              <div className='tab_mobile_text'>
                                <div className='d-flex flex-column w-100'>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./danger.png' style={{ width: "22px" }} alt='danger image' className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'> <span className="fs-16 fw-800 poppins"> Challenge: </span> Ensuring the reliability and quality of suppliers can be difficult for traders without established relationships.</p>
                                    </div>
                                  </div>
                                  <div className='d-flex gap-3'>
                                    <div><img src='./trplogo2.png' alt='danger image' style={{ width: "25px" }} className='' /></div>
                                    <div>
                                      <p className='fs-16 fw-400 poppins tab_para_mobile'><span className="fs-16 fw-800 poppins"> TRP Solution: </span> TRP connects you with a network of thoroughly vetted and trusted suppliers, ensuring you receive high-quality materials consistently.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-5 text-center pt-5 about_trp_img_mobile'>
                            <img src='./tab19.png' className='img_fluid' alt='tab1 image' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
          <div>
          </div>
        </div>

        <div style={{ background: "rgb(57 57 57)" }}>
          <div className='container'>
            <div className='row justify-content-center align-items-center reverse pt-5 pb-5'>
              <div className='col-md-6'>
                <div className='box_background padgn_mobile'>
                  <div><p className='fs-30 fw-700 arvo home_num_mobile'>Procure<span className='text-orange arvo home_num_mobile'>Right</span></p></div>
                  <div>
                    <img src='./backgroundimg.png' style={{ width: "80%" }} />
                  </div>
                </div>
              </div>
              <div className='col-md-6 padding_lign_sec' style={{ padding: "0rem 3rem" }}>
                <h2 className='fs-30 fw-400 arvo text-white para_mobile3' style={{ maxWidth: '483px' }}>Introducing <span className='fs-30 arvo fw-700 para_mobile3'>Procure<span className='text-orange fs-30 arvo fw-700 para_mobile3'>Right<span>:</span></span></span> Your Ultimate Procurement Solution</h2>
                <p className='fs-16 fw-400 poppins para_mobile4' style={{ color: "#d7d7d7" }}>Welcome to ProcureRight, the innovative buyer and seller portal designed exclusively for TRP users. This portal is customized by professionals in the construction industry aiming to solve  complex procurement problems.</p>
                <div>
                  <ul className='text-white ps-3'>
                    <li >
                      <p className='para_text mb-0 poppins'> Accurate Product Selection:</p>
                    </li>
                    <p className='para_text2 poppins para_mobile5'>Enables clients to buy the right products at the right cost.</p>
                  </ul>
                </div>
                <div>
                  <ul className='text-white ps-3'>
                    <li >
                      <p className='para_text mb-0 poppins'>Comprehensive Database:</p>
                    </li>
                    <p className='para_text2 poppins para_mobile5'>Seamlessly navigate our extensive database of construction materials.</p>
                  </ul>
                </div>
                <div className='row m-0 hide'>
                  <div className='col-md-6 p-0'>
                    <p className='mb-0 text-white fs-28 fw-700 arvo'>11000</p>
                    <p className='fs-14 fw-400 text-white arvo'>Products In Our Library</p>
                  </div>
                  <div className='col-md-6 p-0'>
                    <p className='mb-0 text-white fs-28 fw-700 arvo'> 1887</p>
                    <p className='fs-14 fw-400 text-white arvo'>Projects Estimated</p>
                  </div>
                  <div className='col-md-6 p-0'>
                    <p className='mb-0 text-white fs-28 fw-700 arvo'> 3058</p>
                    <p className='fs-14 fw-400 text-white arvo'>Pan India Seller</p>
                  </div>
                  <div className='col-md-6 p-0'>
                    <p className='mb-0 text-white fs-28 fw-700 arvo'> 150</p>
                    <p className='fs-14 fw-400 text-white arvo'> Locations</p>
                  </div>
                </div>
                <div className='container p-0 show1'>
                  <div className="row m-0 justify-content-center align-items-center text-center pt-4 pb-4 p-0 about_trp_img_mobile">
                    <div className='d-flex gap-3 p-0'>
                      <div className='col-md-6'>
                        <div className='text-start'>
                          <p className='mb-0 text-white fw-800 fs-30 arvo home_num_mobile'> 11000</p>
                          <h3 className='fw-400 fs-18 text-white arvo arvo-regular4'>Products In Our Library</h3>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='text-start'>
                          <p className='mb-0 text-white fw-800 fs-30 arvo home_num_mobile'> 1887</p>
                          <h3 className='fw-400 fs-18 text-white arvo arvo-regular4'>Projects Estimated</h3>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex gap-5 p-0'>
                      <div className='col-md-6'>
                        <div className='text-start'>
                          <p className='mb-0 text-white fw-800 fs-30 arvo home_num_mobile'> 3058</p>
                          <h3 className='fw-400 fs-18 text-white arvo arvo-regular4'>Pan India Seller</h3>
                        </div>
                      </div>
                      <div className='col-md-6 ps-4'>
                        <div className='text-start'>
                          <p className='mb-0 text-white fw-800 fs-30 arvo home_num_mobile'> 150</p>
                          <h3 className='fw-400 fs-18 text-white arvo arvo-regular4'> Locations</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container pt-5 pb-5'>
          <div className='row m-0 justify-content-center'>
            <p className="text-center fs-18 fw-400 poppins arvo-regular2 margin_mobile">WHY CHOOSE US</p>
            <div className='col-md-10'>
              <h2 className="text-center fs-30 fw-400 arvo pb-4 para_mobile3">Discover why TRP is the <span className="text-orange fs-30 fw-700 para_mobile3"> one stop solution for our clients </span>for a  Seamless Procurement Solutions.</h2>
            </div>

          </div>

          <div class="row m-0">
            <div class="col-md-12 p-0">
              <div className="wrapper">
                <div className="container p-0">
                  <div class="containers">
                    <input type="radio" name="slide" id="c1" defaultChecked />
                    <label for="c1" class="card position-relative">
                      <div class="card-bg show1 mobile_div p-3">
                        <div className='d-flex align-items-center gap-4'>
                          <img src="./mobileslider2.webp" alt='' style={{ width: "25%" }} />
                          <div>
                            <p class="arvo fs-22 fw-700 text-black arvo-regular5 mb-0">Demand Aggregation</p>
                          </div>
                        </div>
                      </div>

                      <div class="card-bg hide"><p class="arvo my_h">Demand Aggregation</p></div>
                      <div class="row2">
                        <div class="description" style={{ background: "#F2F2F2" }}>
                          <div className='row m-0 justify-content-center reverse'>
                            <div className='col-md-6 imge_aligment_mobile'>
                              <div>
                                <img src="./mobilecombinelabour2.png.png" alt='combilelabour' style={{ width: "100%" }} className='slider_image_mobile' />
                              </div>
                            </div>
                            <div className='col-md-6 d-flex justify-content-center flex-column'>
                              <h4 className='fs-22 fw-700 arvo text-black arvo-regular5'>Demand Aggregation</h4>
                              <p className='fs-14 fw-400 poppins text_aligment_mobile tab_para_mobile'>At TRP, we combine the purchasing power of multiple buyers to secure competitive rates from suppliers. This demand aggregation ensures cost savings and efficiency, providing our clients with the best prices on construction and Industrial materials.</p>
                              <div className='d-flex align-items-center gap-3'>
                                <div className="img_div">
                                  <img src="./hover7.png" alt='hover1' style={{ width: "100%" }} />
                                </div>
                                <span className='poppins fs-16 fw-500 text-black arvo-regular4'>Low Price</span>
                              </div>
                              <div className='d-flex align-items-center gap-3 pt-2 pb-2'>
                                <div className="img_div">
                                  <img src="./hover8.png" alt='hover1' style={{ width: "100%" }} />
                                </div>
                                <span className='poppins fs-16 fw-500 text-black arvo-regular4'>Quick Turn Round Time</span>
                              </div>
                              <div className='d-flex align-items-center gap-3'>
                                <div className="img_div">

                                  <img src="./hover9.png" alt='hover1' style={{ width: "100%" }} />
                                </div>
                                <span className='poppins fs-16 fw-500 text-black arvo-regular4'>Better terms</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>

                    <input type="radio" name="slide" id="c2" />
                    <label for="c2" class="card position-relative slider_mobile_margin">
                      <div class="card-bg show1 mobile_div p-3">
                        <div className='d-flex align-items-center gap-4'>
                          <img src="./mobileslider2.webp" alt='' style={{ width: "25%" }} />
                          <div>
                            <p class="arvo fs-22 fw-700 text-black arvo-regular5 mb-0">Army of 3000+ Sellers</p>
                          </div>
                        </div>
                      </div>
                      <div class="card-bg hide"><p class="arvo my_h">Army of 3000+ Sellers</p></div>

                      <div class="row2">
                        <div class="description" style={{ background: "#F2F2F2" }}>
                          <div className='row m-0 justify-content-center reverse'>
                            <div className='col-md-6 imge_aligment_mobile'>
                              <div>
                                <img src="./combinelabour.webp" alt='combilelabour' style={{ width: "100%" }} className='slider_image_mobile' />
                              </div>
                            </div>
                            <div className='col-md-6 d-flex justify-content-center flex-column'>
                              <h4 className='fs-22 fw-700 arvo text-black arvo-regular5'>Army of 3000+ sellers</h4>
                              <p className='fs-14 fw-400 poppins text_aligment_mobile tab_para_mobile'>TRP proudly partners with an extensive network of over 3000 trusted sellers. This vast supplier base ensures that we can source a wide variety of high-quality construction materials at competitive prices.</p>
                              <div className='d-flex align-items-center gap-3'>
                                <div className="img_div">
                                  <img src="./hover1.webp" alt='hover1' style={{ width: "100%" }} />
                                </div>
                                <span className='poppins fs-16 fw-500 text-black arvo-regular4'>Geographical Distribution</span>
                              </div>
                              <div className='d-flex align-items-center gap-3 pt-2 pb-2'>
                                <div className="img_div">
                                  <img src="./hover2.webp" alt='hover1' style={{ width: "70%" }} />
                                </div>
                                <span className='poppins fs-16 fw-500 text-black arvo-regular4'>One Stop Solution</span>
                              </div>
                              <div className='d-flex align-items-center gap-3'>
                                <div className="img_div">

                                  <img src="./hover3.webp" alt='hover1' style={{ width: "100%" }} />
                                </div>
                                <span className='poppins fs-16 fw-500 text-black arvo-regular4'>Time saving</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>


                    <input type="radio" name="slide" id="c3" />
                    <label for="c3" class="card position-relative">
                      <div class="card-bg show1 mobile_div p-3">
                        <div className='d-flex align-items-center gap-4'>
                          <img src="./mobileslider1.webp" alt='' style={{ width: "25%" }} />
                          <div>
                            <p class="arvo fs-22 fw-700 text-black arvo-regular5 mb-0">Library of 11000 products</p>
                          </div>
                        </div>
                      </div>

                      <div class="card-bg hide"><p class="arvo my_h">Library of 11000 products</p></div>
                      <div class="row2">
                        <div class="description" style={{ background: "#F2F2F2" }}>
                          <div className='row m-0 justify-content-center reverse'>
                            <div className='col-md-6 imge_aligment_mobile'>
                              <div>
                                <img src="./combinelabour3.webp" alt='combilelabour' style={{ width: "100%" }} className='slider_image_mobile2' />
                              </div>
                            </div>
                            <div className='col-md-6 d-flex justify-content-center flex-column'>
                              <h4 className='fs-22 fw-700 arvo text-black arvo-regular5'>Library of 11000 products</h4>
                              <p className='fs-14 fw-400 poppins text_aligment_mobile tab_para_mobile'>TRP offers an extensive library of over 11,000 products, which ensures that our clients can find exactly what they need for any project, all in one place. With such a wide variety of high-quality products, TRP simplifies the procurement process and meets the diverse needs of our customers.</p>
                              <div className='d-flex align-items-center gap-3'>
                                <div className="img_div">
                                  <img src="./hover4.png" alt='hover1' style={{ width: "100%" }} />
                                </div>
                                <span className='poppins fs-16 fw-500 text-black arvo-regular4'>Right Product Specifications</span>
                              </div>
                              <div className='d-flex align-items-center gap-3 pt-2 pb-2'>
                                <div className="img_div">
                                  <img src="./hover5.png" alt='hover1' style={{ width: "70%" }} />
                                </div>
                                <span className='poppins fs-16 fw-500 text-black arvo-regular4'>Option to choose</span>
                              </div>
                              <div className='d-flex align-items-center gap-3'>
                                <div className="img_div">
                                  <img src="./hover6.png" alt='hover1' style={{ width: "100%" }} />
                                </div>
                                <span className='poppins fs-16 fw-500 text-black arvo-regular4'>Informed Procurement</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: "#F2F2F2" }}>
          <div className='container'>
            <div className='row m-0 justify-content-center pt-5 pb-5 mobile_gif'>
              <p className="text-center fs-18 fw-400 poppins arvo-regular2 margin_mobile">OUR PORTALS</p>
              <div className='col-md-10'>
                <h2 className="text-center fs-30 fw-400 arvo para_mobile3">Efficient Procurement Solutions for <span className="text-orange fs-30 fw-700 para_mobile3"> Buyers And Sellers </span>with Procureright.</h2>
                <div class='row m-0 justify-content-center pt-4'>
                  <div className='tabs'>
                    <div className='buy_vende_btn'>
                      <div>
                        <div>
                          <button
                            className={`tab_btns ${activeTab === 'tab1' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab1')}
                          >
                            Buyer
                          </button>
                        </div>
                      </div>
                      <div>
                        <button
                          className={`tab_btns ${activeTab === 'tab2' ? 'active' : ''}`}
                          onClick={() => setActiveTab('tab2')}>
                          Seller
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row m-0'>
                <div className='col-md-12 p-0 pt-4 about_trp_img_mobile'>
                  {activeTab === 'tab1' && (
                    <div className="tab-content">
                      <div class="tab-content" id="pills-tabContent">
                        <div className='gif_div pt-5 pb-5 hide'>
                          <img src="./animatedvideo5.gif" alt="GIF" style={{ width: "100%" }} />
                        </div>
                        <div className='gif_div show1 pt-3'>
                          <img src="./mobilefirstgif.gif" alt="GIF" style={{ width: "100%" }} />
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 'tab2' && (
                    <div className="tab-content">

                      <div class="tab-content" id="pills-tabContent">
                        <div className='gif_div pt-5 pb-5 hide'>
                          <img src="./animatedvideo2.gif" alt="GIF" style={{ width: "100%" }} />
                        </div>
                        <div className='gif_div show1 pt-3'>
                          <img src="./mobilesecondgif.gif" alt="GIF" style={{ width: "100%" }} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='container pt-5'>
          <div className="row m-0 justify-content-center">
            <p className="text-center fs-18 fw-400 poppins arvo-regular2 margin_mobile">SECTORS</p>
            <div className='col-md-12 d-flex justify-content-center'>
              <h2 className="text-center fs-30 fw-400 arvo pb-4 para_mobile6">TRP is catering to <span className="text-orange fs-30 fw-700 para_mobile6">diverse sectors</span> with its sector agnostic approach.</h2>
            </div>
          </div>
          <div className='row justify-content-center m-0'>
            <div className="col-md-11 text-center pb-3 d-flex justify-content-center">
              <p className="fs-16 fw-400 poppins home_text_mobile">
                Dive into TRP's expansive network catering to various architectural sectors, ensuring quality materials for diverse projects. Experience our tailored procurement solutions designed to meet the unique needs of each sector, delivering excellence every step of the way.
              </p>
            </div>
          </div>

          <div className='container p-0 show1'>
            <div className='row m-0 justify-content-center align-items-center'>
              <div className='d-flex justify-content-between p-0'>
                {categories.slice(0, 3).map((category, index) =>
                  category.name === 'All Products' ? null : (
                    <div key={category._id} className='col-md-6 p-2'>
                      <div style={{ position: "relative" }}>
                        <div className='img_box'>
                          <Link to={`/services/${category.name}/${category._id}`} onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}>
                            <img src={images[index - 1]} alt={category.name} style={{ width: '100%' }} />
                            <div className='home_image_text'>
                              <div>
                                <p>{category.name}</p>
                              </div>
                              <div>
                                <img src="./arrowrightright.webp" style={{ width: "85%" }} alt="arrow" />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className='d-flex justify-content-between p-0'>
                {categories.slice(3, 5).map((category, index) =>
                  category.name === 'All Products' ? null : (
                    <div className='col-md-6 p-2'>
                      <div style={{ position: "relative" }}>
                        <div className='img_box'>
                          <Link to={`/services/${category.name}/${category._id}`} onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}>
                            <img src={images[index + 2]} alt={category.name} style={{ width: '100%' }} />
                            <div className='home_image_text'>
                              <div>
                                <p>{category.name}</p>
                              </div>
                              <div>
                                <img src="./arrowrightright.webp" style={{ width: "85%" }} />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className='d-flex justify-content-between p-0'>
                {categories.slice(5, 7).map((category, index) =>
                  category.name === 'All Products' ? null : (
                    <div className='col-md-6 p-2'>
                      <div style={{ position: "relative" }}>
                        <div className='img_box'>
                          <Link to={`/services/${category.name}/${category._id}`} onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}>
                            <img src={images[index + 4]} alt={category.name} style={{ width: '100%' }} />
                            <div className='home_image_text'>
                              <div>
                                <p>{category.name}</p>
                              </div>
                              <div>
                                <img src="./arrowrightright.webp" style={{ width: "85%" }} />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}

              </div>
              <div className='d-flex justify-content-between p-0'>
                {categories.slice(7, 9).map((category, index) =>
                  category.name === 'All Products' ? null : (
                    <div className='col-md-6 p-2'>
                      <div style={{ position: "relative" }}>
                        <div className='img_box'>
                          <Link to={`/services/${category.name}/${category._id}`} onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}>
                            <img src={images[index + 6]} alt={category.name} style={{ width: '100%' }} />
                            <div className='home_image_text'>
                              <div>
                                <p>{category.name}</p>
                              </div>
                              <div>
                                <img src="./arrowrightright.webp" style={{ width: "85%" }} />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className='d-flex justify-content-between p-0'>

                {categories.slice(8, 10).map((category, index) =>
                  category.name === 'All Products' ? null : (
                    <div className='col-md-6 p-2'>
                      <div style={{ position: "relative" }}>
                        <div className='img_box'>
                          <Link to={`/services/${category.name}/${category._id}`} onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}>
                            <img src={images[index + 8]} alt={category.name} style={{ width: '100%' }} />
                            <div className='home_image_text'>
                              <div>
                                <p>{category.name}</p>
                              </div>
                              <div>
                                <img src="./arrowrightright.webp" style={{ width: "85%" }} />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}




              </div>
            </div>
          </div>
          {/* for desktop */}
          <div className='row m-0 justify-content-center hide'>
            <div className='col-md-12 p-0'>
              <div className="image_anchor2">
                {categories.slice(0, 6).map((category, index) =>
                  category.name === 'All Products' ? null : (
                    <div key={index} style={{ position: "relative" }}>
                      <div className='img_box'>
                        <Link to={`/services/${category.name}/${category._id}`} onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}>
                          <img src={images[index] ? images[index] : '/home1.webp'} alt={category.name} style={{ width: '100%' }} />
                          <div className='home_image_text'>
                            <div>
                              <p>{category.name}</p>
                            </div>
                            <div>
                              <img src="./arrowrightright.webp" style={{ width: "85%" }} alt="arrow right" />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="image_anchor2 pt-4">
                {categories.slice(6).map((category, index) =>
                  category.name === 'All Products' ? null : (
                    <div key={index} style={{ position: "relative" }}>
                      <Link to={`/services/${category.name}/${category._id}`} onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}>
                        <img src={images[6 + index] ? images[6 + index] : '/home1.webp'} alt={category.name} style={{ width: '100%' }} />
                        <div className='home_image_text'>
                          <div>
                            <p>{category.name}</p>
                          </div>
                          <div>
                            <img src="./arrowrightright.webp" style={{ width: "85%" }} alt="arrow right" />
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                )}
              </div>



            </div>
          </div>
        </div>

        <div className="container pt-5 pb-5">
          <div className="border_radius" style={{ background: "#f5f5f5" }}>
            <div className="row justify-content-center align-items-center p-5 padding_sec_mobile">
              <div className="col-md-6">
                <h2 className="text-black fs-30 fw-400 arvo para_mobile3" style={{ maxWidth: '454px' }}>
                  Empowering <span className="text-orange fs-30 arvo fw-700 para_mobile3">MSME Vendors</span>: Our Commitment to Growth
                </h2>
                <p className="fs-16 fw-400 poppins para_mobile4" style={{ width: "91%", color: "#393939" }}>
                  At TRP, we firmly believe in fostering economic growth and supporting local businesses. That's why we proudly promote Micro, Small, and Medium Enterprises (MSMEs) as part of our procurement strategy. By prioritizing partnerships with MSME vendors, we not only contribute to the development of local economies but also ensure a diverse and sustainable supply chain.
                </p>
              </div>
              <div className="col-md-6">
                <img src="./coupleimage.png" className="w-100" alt="Couple" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Home;
