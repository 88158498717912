import React, { useState, useEffect, useRef } from 'react';
import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import "./contactus.css";
import "./mobilecontactresponsive.css";
import { Link } from "react-router-dom"
import { HashLink as LinkTo } from 'react-router-hash-link';
const ContactUs = () => {
  const accordionData = [
    {
      title: 'Which regions does TRP currently cover in its service?',
      content: `Currently, we are actively meeting the HVAC, Electrical, Firefighting, Plumbing, Water Treatment, and Compressed Air materials needs of our clients across India.`
    },
    {
      title: 'Which products does TRP currently offer or provide?',
      content: `At present, ProcureRight, our technology platform, boasts a product library featuring over 10,000 products spanning across 80+ categories. These encompass HVAC, plumbing, firefighting, water treatment, LT and HT power distribution, fire detection, and a diverse range of construction materials related to building services, including elevators, PA systems, lightning protection, and more.`
    },
    {
      title: 'What are the steps to initiate the procurement process once I have gained an understanding of TRPs services?',
      content: `You may send your enquiry through following ways:
    • To initiate the procurement process via the website, select "I am a Buyer." You will be redirected to https://buyer.procureright.in/. Sign up by completing a brief inquiry form. Upon logging into ProcureRight, you can locate your desired products in the relevant category. Alternatively, click on the BOQ tab to upload your project's Bill of Quantities (BOQ) or Bill of Materials (BOM) along with specifications and an approved list of makes.
    • Via Email – Send your inquiry to procure@procureright.in, including all the necessary details.
    • Through WhatsApp at +91 7011558052. Please provide your name, email ID, and phone number (if different from your WhatsApp number).
    • Based on this enquiry you will be contacted by our technical team to understand the following
        ◦ Expected procurement dates.
        ◦ Specifications and quantities.
        ◦ Any preferred makes.
        ◦ Delivery locations.
    • If you need any assistance give us a call, however we do not take orders over the phone. You may contact us on +91 7011558052, Call Timings:- 10:00 AM - 6:00 PM (Monday to Friday).`},
    {
      title: 'Does TRP handle direct material supply?',
      content: `TRP operates on a hybrid model, combining Trade and Marketplace approaches. 
                For products estimated by TRP, certain items will be directly sold by suppliers to buyers, while TRP will buy and sell others`
    },
    {
      title: 'To whom should I make payment for the procured materials?',
      content: `TRP operates on a hybrid model, incorporating both Trade and Marketplace approaches. 
• For Marketplace transactions, clients are required to make direct payments to the supplier. 
In Trading scenarios, clients need to make payments to TRP for their materials.`
    },
    {
      title: 'What is the typical Turnaround Time (TAT) for receiving estimates from TRP after submitting an inquiry?',
      content: `As TRP is committed to assisting buyers in acquiring the right products at the best prices, each inquiry undergoes a techno-commercial evaluation process. Depending on the nature of the inquiry, the Turnaround Time (TAT) may range from 3-7 working days or more, especially for specialized engineering products.`
    },
    {
      title: 'What factors contribute to the Turnaround Time ranging between 3-7 working days or more?',
      content: `Each inquiry is unique, influenced by the following parameters:
The distinction between a standard and a customized product. For instance, customized items like electrical panels or elevators require a thorough understanding of client requirements, resulting in a longer associated Turnaround Time (TAT) compared to standard items such as wires, pipes, or tanks.
TRP's make-agnostic approach entails negotiating every inquiry with multiple vendors and manufacturers to secure the best techno-commercial deal for the buyer.
Many inquiries lack complete technical specifications. As Construction-Procurement Professionals, we transform unspecified or partially specified product details into a meaningful inquiry. For instance, someone seeking to procure a pump may not provide all necessary specifications; therefore, we ensure that the inquiry sent to the vendor is as well-specified as possible, considering more than 25 specifications that govern the selection of the right pump.`
    },
    {
      title: 'Can TRP assist us in procuring individual products like window or split air conditioners, refrigerators, electronic items, etc.?',
      content: `At the moment, TRP may not be able to assist you in procuring those specific items.`
    },
    {
      title: 'Who constitutes the client base of TRP?',
      content: `TRP currently is offering its services to following type of clients
Manufacturing Companies
Contractors
EPC Companies
Non Construction Businesses like Restaurants, Hotels etc.
Is TRP catering to domestic buying requirements.`
    },
    {
      title: 'How to proceed with ordering from TRP? ',
      content: `Following the review of your inquiries, we will provide a cost estimate along with techno-commercial details, accessible on your ProcureRight dashboard under 'Estimation.' 
Feel free to connect with our teams for clarification or additional information regarding the submitted estimates. 
Once you have reviewed the estimates, you can proceed to place an order with TRP Construction Management Pvt. Ltd. for the required materials. We will then supply the materials in accordance with the mutually agreed terms and conditions outlined in the Purchase Order (PO).s.`
    }
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const toggleAccordion = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqRef = useRef(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#faq' && faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 p-0 position-relative'>
            <img src="./contact_c.jpg" alt="Water" className='contact_mobile_img' />
            <nav aria-label="breadcrumb" className='for_poss' style={{ position: "absolute", top: "24px", left: "60px", zIndex: "1" }}>
              <ol class="breadcrumb text-white ps-1">
                <li className="text-white">
                  <Link to="/" className="text-white fs-14" style={{ textDecoration: "none" }}>
                    Home
                  </Link>
                  <i className="fa-solid fa-greater-than fs-9 ps-2 pe-2"></i>
                </li>
                <li class="breadcrumb-item text-white"><a href="#" className='text-white fs-14' style={{ textDecoration: "none" }}>Contact Us</a></li>
              </ol>
            </nav>
            <h2 className='for_f'>Contact us</h2>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row justify-content-around mobile_padding align-items-center pt-5 pb-5'>
          <div className='col-md-11 padding_align'>
            <div className='row m-0 justify-content-center'>
              <div className='col-md-4 d-flex justify-content-center p-0'>
                <div className='add_c'>
                  <img src="./location_c.png" height={'50px'} alt="location" />
                  <p className='m-0 fw-700 pt-2 arvo para_mobile fs-20'>Address</p>
                  <p className='m-0 fs-15 fw-400 poppins pt-1 font_mobile' style={{ color: "#000000" }}>26, Vipul Agora Mall, MG Road ,Gurugram, Haryana-122002</p>
                </div>
              </div>
              <div className='col-md-4 d-flex justify-content-center p-0'>
                <div className='add_c'>
                  <img src="./call_c.png" height={'50px'} alt="location" />
                  <p className='m-0 fw-700 pt-2 arvo para_mobile fs-20'>Phone Number</p>
                  <p className='m-0 fs-15 fw-400 poppins pt-1 font_mobile' style={{ color: "#000000" }}><Link to='' style={{ color: 'black', textDecoration: 'none' }}> +91-7011558052</Link></p>
                </div>
              </div>
              <div className='col-md-4 d-flex justify-content-center p-0'>
                <div className='add_c'>
                  <img src="./mail_c.png" height={'50px'} alt="location" />
                  <p className='m-0 fw-700 pt-2 arvo para_mobile fs-20'>Email Id</p>
                  <p className='m-0 fs-15 fw-400 poppins pt-1 font_mobile' style={{ color: "#000000" }}> <Link to='' style={{ color: 'black', textDecoration: 'none' }}>Procure@procureright.in</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row m-0 justify-content-center pb-5'>
          <div className='col-md-11'>
            <div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4418.507629560204!2d77.08352036184303!3d28.480378277782602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1924064684ff%3A0xa8f3c6de5a674aa2!2sVipul%20Agora%20Mall!5e0!3m2!1sen!2sin!4v1721978169139!5m2!1sen!2sin" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='mobile_height'></iframe>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "rgb(243 243 242)" }} >
        <div className='container' >
          <div className='row justify-content-between pt-5 pb-5'>
            <div className='col-md-4 padding_desk' ref={faqRef}>
              <div className='container_mobile2'>
                <p className='fs-14 fw-400 m-0 poppins pb-3'>F.A.Q.</p>
                <p className='fs-30 fw-600 m-0 arvo para_mobile2' style={{ color: "#FFBD53", lineHeight: "30px" }}>Any Questions <span className='text-black'>?</span> </p>
                <p className='fs-30 fw-500 m-0 pb-2 arvo para_mobile2' style={{ lineHeight: "38px" }}> We got you.</p>
                <p className='poppins fs-16 fw-400 font_mobile' style={{ color: "#393939" }}>Explore answers to common inquiries about TRP's procurement services and processes. Find quick solutions to your procurement queries.</p>
              </div>
            </div>
            <div className='col-md-7' >
              <div className='contact_faq'>
                {accordionData.map((item, index) => (
                  <div className="accordion_1" key={index}>
                    <div className="accordion-item_1">
                      <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                        <div className='arvo-regular arvo'>{item.title}</div>
                        <div>{activeIndex === index ? '-' : '+'}</div>
                      </div>
                      {activeIndex === index && <div className="accordion-content poppins">{item.content}</div>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
