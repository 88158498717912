import React, { useState, useEffect, useContext } from 'react';
import './footer.css';
import axios from 'axios'
import './mobilefooterresponsive.css';
import { Link } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import Linkdin from './linkdin.webp';
import Twitter from './twitter.webp';
import Facebooks from './facebook.webp';
import Email from './email.webp';
import Mobile from './mobile.webp';
import Location from './location.webp';
import navbarlogo from './navbarlogo.png';
import Insta from './insta.webp';
import LogoHasmuksh from './logohasmukh.png';
import NavBarlogo from './navbarlogo.png';
import Trpwhatapp from './trpwhatapp.webp';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CategoryContext } from '../../context/CategoryContext';
import { ProductContext } from '../../context/ProductContext';

const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <button
      onClick={scrollToTop}
      className="scroll-top text-black"
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <FaArrowUp />
    </button>
  );
};











const Footer = () => {
  const BaseUrl = 'https://trpmanagement.s3.ap-south-1.amazonaws.com/';
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const { catId, setCatId, categoryName, setCategoryName, setSubCatId, setSubSubCatId, bannerImageUrl, setBannerImageUrl, setCurrentSubSubCategoryId, setSubCurrentPage } = useContext(CategoryContext);
  const { productSubcatId, subCatProductName, setProductSubcatId, setProductImageUrl, setSubCatProductName, subcategoryImage, subcategoryBannerImage, setSubcategoryImage, setSubcategoryBannerImage, subSubCategoryId, setSubSubCategoryId, page, setPage } = useContext(ProductContext);
  const [isDivVisible, setDivVisible] = useState(false);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://website.procureright.in/api/webcategories/all', {
          headers: {
            // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiIxMTI2IiwiaWF0IjoxNjkyNzczMjU3fQ.QQhOYSAfbbhMSt1RRXa3bm_HzucnEBeVPsbpa_F8yug'
          }
        });
        console.log(response.data)
        setCategories(response.data);

      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubproduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webcategories/subcategorylist?categoryId=25`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_CATEGORY}`
          }
        });
        setSubCategories(response.data[0].subWebCategories);

      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchSubproduct();
  }, []);

  const toggleDiv = () => {
    setDivVisible(!isDivVisible);
  };
  const handleCategoryClick = (id, name, bannerImage) => {
    setCatId(id);
    setCategoryName(name);
    setBannerImageUrl(BaseUrl + bannerImage);
    setSubCatId('');
    setSubSubCatId('');
    setCurrentSubSubCategoryId(null)
    setSubCurrentPage(1)
  };
  const producthandleCategoryClick = (id, name, bannerImage, image) => {
    setProductSubcatId(id);
    setSubSubCategoryId(null)
    setPage(1)
    setSubCatProductName(name);
    setSubcategoryBannerImage(BaseUrl + bannerImage);
    setSubcategoryImage(BaseUrl + image);
  };

  return (
    <div>
      <div className='show1'>
        <div className="row m-0">
          <div className="bg-orange d-flex justify-content-center">
            <div className="col-md-12 d-flex justify-content-center text-center pt-5 pb-5 padgn_mobile">
              <div>
                <h2 className="fs-16 mb-0 fw-500 arvo footer_mobile" style={{ width: "100%" }}>
                  “End The Chase With Vendors. Let TRP Handle All Your Procurement Needs Seamlessly.”
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: "#F2F2F2" }}>
          <div className="">
            <div className="row m-0 justify-content-center">
              <div className="col-md-12 pt-4 pb-4">
                <img src={NavBarlogo} alt="trp logo" style={{ width: "65%" }} />
              </div>
              <div className='row m-0 pt-2'>
                <h3 className="mb-0 fs-15 fw-700 arvo footer_padding2 pb-3">PRODUCTS</h3>
                <div className='d-flex p-0 justify-content-between'>
                  <div className='col-md-6'>
                    <ul className="p-0">

                      {subCategories.slice(0, 5).map(subCategory => (
                        <li key={subCategory._id} className="list_style">
                          <Link
                            to={`/AllProductCategory/${subCategory.name}/${subCategory._id}`}
                            className="list_style"
                            onClick={() => producthandleCategoryClick(subCategory._id, subCategory.name, subCategory.bannerImage, subCategory.image)}
                          >
                            {subCategory.name}
                          </Link>
                        </li>
                      ))}

                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className="p-0">
                      {subCategories.slice(6).map(subCategory => (
                        <li key={subCategory._id} className="list_style">
                          <Link
                            to={`/AllProductCategory/${subCategory.name}/${subCategory._id}`}
                            className="list_style"
                            onClick={() => producthandleCategoryClick(subCategory._id, subCategory.name, subCategory.bannerImage, subCategory.image)}
                          >
                            {subCategory.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='row m-0 pt-2'>
                <h3 className="mb-0 fs-15 fw-700 arvo footer_padding2 pb-3">SECTORS</h3>
                <div className='d-flex p-0' style={{ gap: "45%" }}>
                  <div className='col-md-6'>
                    <ul className="p-0">

                      {categories.slice(0, 7).map((category, index) =>
                        category.name === 'All Products' ? null : (
                          <div className="col-md-6" key={index}>
                            <ul className="p-0">
                              <li className="list_style">
                                <Link
                                  to={`/services/${category.name}/${category._id}`}
                                  className="list_style"
                                  onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}
                                >
                                  {category.name}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )
                      )}

                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className="p-0">

                      {categories.slice(7).map((category, index) =>
                        category.name === 'All Products' ? null : (
                          <div className="col-md-6" key={index}>
                            <ul className="p-0">
                              <li className="list_style">
                                <Link
                                  to={`/services/${category.name}/${category._id}`}
                                  className="list_style"
                                  onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}
                                >
                                  {category.name}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )
                      )}

                    </ul>
                  </div>
                </div>
              </div>
              <div className='row m-0 pt-2'>
                <h3 className="mb-0 fs-15 fw-700 arvo footer_padding2 pb-3">QUICK LINKS</h3>
                <div className='d-flex justify-content-between p-0'>
                  <div className='col-md-6'>
                    <ul className="p-0">
                      <Link to="https://trp-tsting.vercel.app/AboutUs" className="list_style">
                        <li className="list_style">About us</li>
                      </Link>
                      <Link to="https://trp-tsting.vercel.app/ContactUs" className="list_style">
                        <li className="list_style">FAQ</li>
                      </Link>
                      <Link to="https://trp-tsting.vercel.app/ContactUs" className="list_style">
                        <li className="list_style">Contact us</li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='row m-0 pt-2'>
                <h3 className="mb-0 fs-15 fw-700 arvo footer_padding2 pb-3">CONTACT US</h3>
                <div className='d-flex justify-content-between p-0'>
                  <div className='col-md-6' style={{ width: "76%" }}>
                    <div>
                      <div className='d-flex align-items-center pt-2' style={{ gap: "20px" }}>
                        <div><img src={Location} alt='Location' /></div>
                        <div className='fotter_contact_data_mobile'>26, Vipul Agora Mall, MG Road ,Gurugram, Haryana-122002</div>
                      </div>
                      <div className='d-flex align-items-center pt-2' style={{ gap: "20px" }}>
                        <div><img src={Mobile} alt='mobile image' /></div>
                        <div className='fotter_contact_data_mobile'>
                          <Link to='' style={{ color: '#393939', textDecoration: 'none' }}>
                          +91-7011558052 </Link></div>
                      </div>
                      <div className='d-flex align-items-center pt-2' style={{ gap: "20px" }}>
                        <div><img src={Email} alt='email image' /></div>
                        <div className='fotter_contact_data_mobile'><Link to='' style={{ color: '#393939', textDecoration: 'none' }}> procure@procureright.in </Link></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row m-0'>
                <div className="col-md-12 pt-4 pb-4">
                  <div className="socila_icons2">
                    <div>
                      <Link to="https://www.linkedin.com/company/digital-trp/">
                        <img src={Linkdin} alt="linkdin" />
                      </Link>
                    </div>
                    {/* <div> */}
                    {/* <Link to="">
                        <img src={Twitter} alt="twitter" />
                      </Link> */}
                    {/* </div> */}
                    <div>
                      <Link to="https://www.facebook.com/digitaltrp/">
                        <img src={Facebooks} alt="facebook" />
                      </Link>
                    </div>
                    <div>
                      <Link to="">
                        <img src={Insta} alt="insta" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row m-0 justify-content-center align-items-center">
                    <div className="col-md-12 p-0">
                      <div className="footer_underline"></div>
                    </div>
                    <div className="container p-0">
                      <div className="row m-0 justify-content-center align-items-center pt-4 pb-4">
                        <div className="col-md-7 text-start p-0">
                          <p className="fs-12 fw-400 mb-0 poppins">
                            © 2024 All Rights Reserved by TRP Construction Management PVT LTD. | Disclaimer | Copyright Notice | Privacy Policy
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* {/ mobile_responsive /} */}
      <div className='hide'>
        <div className="row m-0">
          <div className="bg-orange d-flex justify-content-center">
            <div className="col-md-12 d-flex justify-content-center text-center pt-5 pb-5">
              <div>
                <h2 className="fs-25 mb-0 fw-500 arvo" style={{ color: "#000000" }}>
                  “End The Chase With Vendors. Let TRP Handle All Your Procurement Needs Seamlessly.”
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: "#F2F2F2" }}>
          <div className="web_footer_padding">
            <div className="row m-0 justify-content-center">
              <div className="row m-0 justify-content-center align-items-center p-0">
                <div className="col-md-2 p-0">
                  <img src={NavBarlogo} alt="trp logo" style={{ width: "85%" }} />
                </div>
                <div className="col-md-3 ps-0">
                  <h3 className="mb-0 fs-14 fw-700 arvo footer_padding">PRODUCTS</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="mb-0 fs-14 fw-700 arvo footer_padding">SECTORS</h3>
                </div>
                <div className="col-md-4">
                  <div className="row m-0">
                    <div className="col-md-6 p-0">
                      <h3 className="mb-0 fs-14 fw-700 arvo footer_padding2" style={{ color: "#393939" }}>QUICK LINKS</h3>
                    </div>
                    <div className="col-md-6 p-0">
                      <h3 className="mb-0 fs-14 fw-700 arvo footer_padding2" style={{ color: "#393939" }}>CONTACT US</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 pt-4 p-0">
                <p className="fs-10 fw-400 poppins p-0" style={{ lineHeight: "1.5" }}>
                  TRP connects you with over 3,000 vendors, offering a diverse selection of 11,000+ construction materials. Trust us for all your procurement needs, ensuring quality and efficiency every step of the way.
                </p>
                <div className="socila_icons">
                  <div>
                    <Link to="https://www.linkedin.com/company/digital-trp/">
                      <img src={Linkdin} alt="linkdin" style={{ width: "75%" }} />
                    </Link>
                  </div>
                  <div>
                    <Link to="https://www.facebook.com/digitaltrp/">
                      <img src={Facebooks} alt="facebook" style={{ width: "75%" }} />
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <img src={Insta} alt="insta" style={{ width: "75%" }} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row m-0">
                  <div className="col-md-6 p-0">
                    <ul className="p-0 mb-0">
                      {/* Slice the first 6 items for the first column */}
                      {subCategories.slice(0, 5).map(subCategory => (
                        <li key={subCategory._id} className="list_style">
                          <Link
                            to={`/AllProductCategory/${subCategory.name}/${subCategory._id}`}
                            className="list_style"
                            onClick={() => producthandleCategoryClick(subCategory._id, subCategory.name, subCategory.bannerImage, subCategory.image)}
                          >
                            {subCategory.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="p-0 mb-0">
                      {/* Slice the remaining items for the second column */}
                      {subCategories.slice(6).map(subCategory => (
                        <li key={subCategory._id} className="list_style">
                          <Link
                            to={`/AllProductCategory/${subCategory.name}/${subCategory._id}`}
                            className="list_style"
                            onClick={() => producthandleCategoryClick(subCategory._id, subCategory.name, subCategory.bannerImage, subCategory.image)}
                          >
                            {subCategory.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="row m-0">
                  {categories.map((category, index) =>
                    category.name === 'All Products' ? null : (
                      <div className="col-md-6" key={index}>
                        <ul className="p-0 mb-0">
                          <li className="list_style">
                            <Link
                              to={`/services/${category.name}/${category._id}`}
                              className="list_style"
                              onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}
                            >
                              {category.name}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="row m-0">
                  <div className="col-md-6">
                    <ul className="p-0">
                      <Link to="/AboutUs" className="list_style">
                        <li className="list_style">About us</li>
                      </Link>
                      <Link to="/ContactUs#faq" className="list_style">
                        <li className="list_style">FAQ</li>
                      </Link>
                      <Link to="/ContactUs" className="list_style">
                        <li className="list_style">Contact us</li>
                      </Link>
                    </ul>
                  </div>
                  <div className="col-md-6 p-0">
                    <div>
                      <div className='d-flex align-items-center pt-2' style={{ gap: "10px" }}>
                        <div><img src={Location} alt='Location' /></div>
                        <div className='fotter_contact_data'>26, Vipul Agora Mall, MG Road ,Gurugram, Haryana-122002</div>
                      </div>
                      <div className='d-flex align-items-center pt-2' style={{ gap: "10px" }}>
                        <div><img src={Mobile} alt='mobile image' /></div>
                        <div className='fotter_contact_data'>
                        <Link to='' style={{ color: '#393939', textDecoration: 'none' }}>
                          +91-7011558052 </Link></div>
                      </div>
                      <div className='d-flex align-items-center pt-2' style={{ gap: "10px" }}>
                        <div><img src={Email} alt='email image' /></div>
                        <div className='fotter_contact_data'>
                        <Link to='' style={{ color: '#393939', textDecoration: 'none' }}>
                          procure@procureright.in</Link></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row m-0 justify-content-center align-items-center">
                <div className="col-md-12 pt-5">
                  <div className="footer_underline"></div>
                </div>
              </div>
              <div className="container p-0">
                <div className="row m-0 justify-content-center align-items-center pt-4">
                  <div className="col-md-7 text-center p-0">
                    <p className="fs-11 fw-400 mb-0 poppins">
                      © 2024 All Rights Reserved by TRP Construction Management PVT LTD. | Disclaimer | Copyright Notice | Privacy Policy
                    </p>
                  </div>
                  <div className="col-md-4 text-center p-0">
                    <div style={{ border: "1px solid #cdcdcd" }}></div>
                  </div>
                  <div className="col-md-1 p-0 text-center d-flex justify-content-end">
                    <button className="bacK_btn">Back to top</button>
                    <ScrollTopButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Link to="https://api.whatsapp.com/send?phone=917011558052" className="whatsapp2">
          <img src={Trpwhatapp} alt="Whatsap Icon" style={{ width: "58px" }} />
        </Link>
        {/* <Link to="" className="logotrp">
          <img src={LogoHasmuksh} alt="Whatsap Icon" style={{ width: "50px" }} />
        </Link> */}

        <div>
          <div className="logotrp" onClick={toggleDiv}>
            <img src={LogoHasmuksh} alt="Whatsap Icon" style={{ width: "50px" }} />
          </div>

          {isDivVisible && (
            <div className="my-div">
              <div className='d-flex justify-content-between align-items-center'>
                <p className='fs-18 fw-700 mb-0 arvo' style={{ color: "#000" }}><span className='model_pop'>Did </span> You Know!</p>
                <button onClick={toggleDiv} className="close-button ps-5"><i class="fa-solid fa-xmark"></i></button>
              </div>
              <p className='fs-14 fw-400 poppins pt-3' style={{ lineHeight: "18px" }}>Our efficient process removes multi-party involvement and uses tech-enabled negotiations, ensuring you save money effortlessly.</p>
            </div>
          )}
        </div>

      </div>

    </div>
  );
};

export default Footer;
