import React, { createContext, useState, useEffect } from "react";

const SearchResultContext = createContext();

const SearchResultProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState(() => {
    // Initialize state from local storage if available
    const storedResults = localStorage.getItem("searchResults");
    return storedResults ? JSON.parse(storedResults) : [];
  });
 const [searchText,setSearchText]=useState("")
 
  const [imageBannerUrl, setImageBannerUrl] = useState(() => {
    // Initialize state from local storage if available
    const storedUrl = localStorage.getItem("imageBannerUrl");
    return storedUrl ? storedUrl : "";
  });

  const [key, setKey] = useState(() => {
    // Initialize state from local storage if available
    const storedUrl = localStorage.getItem("key");
    return storedUrl ? storedUrl : "";
  });

  const [totalCount, setTotalCount] = useState(() => {
    const storedTotalCount = localStorage.getItem("totalCount");
    return storedTotalCount !== null ? JSON.parse(storedTotalCount) : 0;
  });

  const [totalPages, setTotalPages] = useState(() => {
    const storedTotalPages = localStorage.getItem("totalPages");
    return storedTotalPages !== null ? JSON.parse(storedTotalPages) : 0;
  });

  const [currentPage, setCurrentPage] = useState(() => {
    const storedCurrentPage = localStorage.getItem("currentPage");
    return storedCurrentPage !== null ? JSON.parse(storedCurrentPage) : 1;
  });

  const [pageSize, setPageSize] = useState(() => {
    const storedPageSize = localStorage.getItem("pageSize");
    return storedPageSize !== null ? JSON.parse(storedPageSize) : 10;
  });

  const [counts, setCounts] = useState(() => {
    const storedCounts = localStorage.getItem("counts");
    return storedCounts !== null ? JSON.parse(storedCounts) : {};
  });

  useEffect(() => {
    // Store searchResults in local storage whenever it changes
    localStorage.setItem("searchResults", JSON.stringify(searchResults));
  }, [searchResults]);

  useEffect(() => {
    // Store imageBannerUrl in local storage whenever it changes
    localStorage.setItem("imageBannerUrl", imageBannerUrl);
  }, [imageBannerUrl]);

  useEffect(() => {
    // Store totalCount in local storage whenever it changes
    localStorage.setItem("totalCount", JSON.stringify(totalCount));
  }, [totalCount]);

  useEffect(() => {
    // Store totalPages in local storage whenever it changes
    localStorage.setItem("totalPages", JSON.stringify(totalPages));
  }, [totalPages]);

  useEffect(() => {
    // Store currentPage in local storage whenever it changes
    localStorage.setItem("currentPage", JSON.stringify(currentPage));
  }, [currentPage]);

  useEffect(() => {
    // Store pageSize in local storage whenever it changes
    localStorage.setItem("pageSize", JSON.stringify(pageSize));
  }, [pageSize]);

  useEffect(() => {
    // Store counts in local storage whenever it changes
    localStorage.setItem("counts", JSON.stringify(counts));
  }, [counts]);

  return (
    <SearchResultContext.Provider
      value={{
        searchResults,
        setSearchResults,
        imageBannerUrl,
        setImageBannerUrl,
        totalCount,
        setTotalCount,
        totalPages,
        setTotalPages,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        counts,
        setCounts,
        key, setKey,
        searchText,setSearchText
      }}
    >
      {children}
    </SearchResultContext.Provider>
  );
};

export { SearchResultContext, SearchResultProvider };
