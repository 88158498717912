import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ProductContextProvider } from './context/ProductContext'; 
import { CategoryProvider } from './context/CategoryContext';
import { SearchResultProvider} from './context/SearchResultContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ProductContextProvider >
    <CategoryProvider>
      <SearchResultProvider>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
    </SearchResultProvider>
    </CategoryProvider>
  </ProductContextProvider>
);

reportWebVitals();
