import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CategoryContext } from '../context/CategoryContext';
import crpoffice from './crpoffice.png';
import AllProductpng from './All_hospita.png';
import "./mobilesubcatproductresponsive.css";

const SubCatProduct = (ids) => {
  const navigate = useNavigate();
  const { catId, setCatId, categoryName, setCategoryName, setSubCatId, setSubSubCatId, bannerImageUrl, setBannerImageUrl, setCurrentSubSubCategoryId, setSubCurrentPage } = useContext(CategoryContext);
  const BaseUrl = 'https://trpmanagement.s3.ap-south-1.amazonaws.com/';
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://website.procureright.in/api/webcategories/all', {
          headers: {
            // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiIxMTI2IiwiaWF0IjoxNjkyNzczMjU3fQ.QQhOYSAfbbhMSt1RRXa3bm_HzucnEBeVPsbpa_F8yug'
          }
        });
        setCategories(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (id, name, bannerImage) => {
    setCatId(id);
    setCategoryName(name);
    setBannerImageUrl(BaseUrl + bannerImage);
    setSubCatId('');
    setSubSubCatId('');
    setCurrentSubSubCategoryId(null)
    setSubCurrentPage(1)
    navigate(`/services/${name}/${id}`);
  };



  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 p-0 position-relative'>
            <nav aria-label="breadcrumb " className='for_poss' style={{ position: "absolute", top: "24px", left: "61px", zIndex: "1" }}>
              <ol class="breadcrumb text-white ps-1">
                {/* <li class=" text-white"><a href="/" className='text-white  fs-11' style={{ textDecoration: "none" }}>Home</a><i class="fa-solid fa-greater-than fs-9 ps-2 pe-2"></i></li> */}
                <li className="text-white">
                  <Link to="/" className="text-white fs-14" style={{ textDecoration: "none" }}>
                    Home
                  </Link>
                  <i className="fa-solid fa-greater-than fs-9 ps-2 pe-2"></i>
                </li>
                <li class="breadcrumb-item text-white"><a href="#" className='text-white  fs-14' style={{ textDecoration: "none" }}>Sectors</a><i class="fa-solid fa-greater-than fs-9 ps-2 pe-2"></i></li>
                <li class="breadcrumb-item active" aria-current="page" className='text-white  fs-14' style={{ lineHeight: "28px" }}>{categoryName}</li>
              </ol>
            </nav>
            {!loading && (
              <>
                <div className='image-container'>
                  <img src={bannerImageUrl} className='baaner_ig' style={{ width: '100%' }} alt="Banner" />
                  <div className="overlay"></div>
                </div>
                <h2 className='for_f'>{categoryName}</h2>
              </>
            )}
          </div>
        </div>
      </div>

      <div class="container show1">
        <div class="row m-0 pt-3">
          <div class="col" style={{position:"relative"}}>
            <ul class="nav nav-pills ul_tab d-flex flex-nowrap" id="pills-tab" role="tablist" style={{position:"relative"}}>
              {categories.map(category => (
                category.name !== 'All Products' && (
                  <li class="nav-item" role="presentation" key={category._id}>
                    <NavLink
                      to={`/services/${category.name}/${category._id}`}
                      className="nav-link"
                      activeClassName="active"
                      id={`pills-${category._id}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pills-${category._id}`}
                      type="button"
                      role="tab"
                      aria-controls={`pills-${category._id}`}
                      aria-selected="true"
                      onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}
                      style={{ textDecoration: 'none', color: 'black', height: '' }}
                    >
                      {category.name}
                    </NavLink>
                  </li>
                )
              ))}
            </ul>
            <div className='paddings_align2'>
                <div className='tab_underline'></div>
              </div>
          </div>
        </div>
      </div>

      {!loading && (
        <div className='bg_gray'>
          <div className='container'>
            <div className='row pt-4 pb-4 hide'>
              <div className='col-md-12 p-0'>
                <div className='d-flex cate_all2'>
                  {categories.map(category => {
                    if (category.name === 'All Products') return null;
                    return (
                      <div className='text-center width_align' key={category._id}>
                        <NavLink
                          to={`/services/${category.name}/${category._id}`}
                          className={({ isActive }) => isActive ? 'for_w_img2' : 'for_w_img'}
                          onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}
                          style={{ textDecoration: 'none', color: 'black' }}>
                          <div>
                            <img src={BaseUrl + category.image} alt={category.name} style={{ width: '100%' }} />
                          </div>
                        </NavLink>
                        <p className='m-0 fw-400 fs-14 f-af new_width arvo' style={{ color: "#000000" }}>{category.name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubCatProduct;
