import React, { createContext, useState, useEffect } from "react";

const CategoryContext = createContext();

const CategoryProvider = (props) => {
  const [subCatId, setSubCatId] = useState('');
  const [subsubCatId, setSubSubCatId] = useState('');
  const [ currentSubSubCategoryId, setCurrentSubSubCategoryId ] = useState(null);

  const [catId, setCatId] = useState(() => {
    return localStorage.getItem('catId') || '';
  });

  const [categoryName, setCategoryName] = useState(() => {
    return localStorage.getItem('categoryName') || 'All';
  });

  const [bannerImageUrl, setBannerImageUrl] = useState(() => {
    return localStorage.getItem('bannerImageUrl') || '';
  });

  const [subTotalCount, setSubTotalCount] = useState(() => {
    return localStorage.getItem('subTotalCount') || '';
  });

  const [subTotalPages, setSubTotalPages] = useState(() => {
    return localStorage.getItem('subTotalPages') || '';
  });

  const [subCurrentPage, setSubCurrentPage] = useState(() => {
    return localStorage.getItem('subCurrentPage') || '';
  });

  const [subPageSize, setSubPageSize] = useState(() => {
    return localStorage.getItem('subPageSize') || '';
  });

  const [subCounts, setSubCounts] = useState(() => {
    return localStorage.getItem('subCounts') || '';
  });

  useEffect(() => {
    localStorage.setItem('catId', catId);
  }, [catId]);

  useEffect(() => {
    localStorage.setItem('categoryName', categoryName);
  }, [categoryName]);

  useEffect(() => {
    localStorage.setItem('bannerImageUrl', bannerImageUrl);
  }, [bannerImageUrl]);

  useEffect(() => {
    localStorage.setItem('subTotalCount', subTotalCount);
  }, [subTotalCount]);

  useEffect(() => {
    localStorage.setItem('subTotalPages', subTotalPages);
  }, [subTotalPages]);

  useEffect(() => {
    localStorage.setItem('subCurrentPage', subCurrentPage);
  }, [subCurrentPage]);

  useEffect(() => {
    localStorage.setItem('subPageSize', subPageSize);
  }, [subPageSize]);

  useEffect(() => {
    localStorage.setItem('subCounts', subCounts);
  }, [subCounts]);

  return (
    <CategoryContext.Provider value={{
      catId, setCatId,
      categoryName, setCategoryName,
      subCatId, setSubCatId,
      subsubCatId, setSubSubCatId,
      bannerImageUrl, setBannerImageUrl,
      subTotalCount, setSubTotalCount,
      subTotalPages, setSubTotalPages,
      subCurrentPage, setSubCurrentPage,
      subPageSize, setSubPageSize,
      subCounts, setSubCounts,
      currentSubSubCategoryId, setCurrentSubSubCategoryId 
    }}>
      {props.children}
    </CategoryContext.Provider>
  );
};

export { CategoryContext, CategoryProvider };
