import React, { useContext, useEffect, useState } from 'react';
import { NavLink,useNavigate } from 'react-router-dom';
import axios from 'axios';
import MySpinner from './trp-logo.gif';
import { ProductContext } from '../context/ProductContext';
import AllProductpng from './residential.png';
import './allcategoryheader.css';
import { Link } from "react-router-dom"

const CategoryHeader = () => {
 const navigate=useNavigate ();
  const { productSubcatId, subCatProductName, setProductSubcatId, setProductImageUrl, setSubCatProductName, subcategoryImage, subcategoryBannerImage, setSubcategoryImage, setSubcategoryBannerImage, subSubCategoryId, setSubSubCategoryId, page, setPage } = useContext(ProductContext);
  const BaseUrl = 'https://trpmanagement.s3.ap-south-1.amazonaws.com/';
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webcategories/subcategorylist?categoryId=25`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_CATEGORY}`
          }
        });
        setSubCategories(response.data[0].subWebCategories);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);
  
  const handleCategoryClick = (id, name, bannerImage, image) => {
    setProductSubcatId(id);
    setSubSubCategoryId(null)
    setPage(1)
    setSubCatProductName(name);
    setSubcategoryBannerImage(BaseUrl + bannerImage);
    setSubcategoryImage(BaseUrl + image);
    navigate(`/AllProductCategory/${name}/${id}`);
   
  };

  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 p-0 position-relative'>
            {loading ? (
              <img src={MySpinner} alt="Loading..." style={{ display: 'block', margin: '0 auto' }} />
            ) : (
              <div className='col-md-12 p-0 position-relative'>
                <nav aria-label="breadcrumb " className='for_poss' style={{ position: "absolute", top: "24px", left: "61px", zIndex: "1" }}>
                  <ol class="breadcrumb text-white ps-1">
                    <li className="text-white">
                      <Link to="/" className="text-white fs-16" style={{ textDecoration: "none" }}>
                        Home
                      </Link>
                      <i className="fa-solid fa-greater-than fs-9 ps-2 pe-2"></i>
                    </li>
                    <li class="breadcrumb-item text-white"><a href="#" className='text-white  fs-14' style={{ textDecoration: "none" }}>Product</a><i class="fa-solid fa-greater-than fs-9 ps-2 pe-2"></i></li>
                    <li class="breadcrumb-item active" aria-current="page" className='text-white  fs-14' style={{ lineHeight: "28px" }}>{subCatProductName}</li>
                  </ol>
                </nav>
                <img src={subcategoryBannerImage} className='baaner_ig' style={{ width: '100%' }} alt="Banner" />
                <div className="overlay"></div>
              </div>
            )}
            {!loading && <h2 className='for_f'>{subCatProductName}</h2>}
          </div>
        </div>
      </div>

      <div class="container show1">
        <div class="row m-0 pt-3">
          <div class="col" style={{ position: "relative" }}>
            <ul class="nav nav-pills ul_tab d-flex flex-nowrap" id="pills-tab" role="tablist" style={{ position: "relative" }}>
              {subCategories.map(category => (
                category.name !== 'All Products' && (
                  <li class="nav-item" role="presentation" key={category._id}>
                    <NavLink
                      to={`/AllProductCategory/${category.name}/${category._id}`}
                      className="nav-link"
                      activeClassName="active"
                      id={`pills-${category._id}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pills-${category._id}`}
                      type="button"
                      role="tab"
                      aria-controls={`pills-${category._id}`}
                      aria-selected="true"
                      onClick={() => handleCategoryClick(category._id, category.name, category.bannerImage)}
                      style={{ textDecoration: 'none', color: 'black', height: '' }}
                    >
                      {category.name}
                    </NavLink>
                  </li>
                )
              ))}
            </ul>
            <div className='paddings_align2'>
              <div className='tab_underline'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg_gray'>
        <div className='container'>
          <div className='row pt-4 pb-4 hide'>
            <div className='col-md-12 p-0'>
              <div className='d-flex cate_all2'>
                {subCategories.map(subCategory => (
                  <div className='text-center width_align' key={subCategory._id}>
                    <div className='d-flex justify-content-center'>
                      <NavLink
                        to={`/AllProductCategory/${subCategory.name}/${subCategory._id}`}
                        onClick={() => handleCategoryClick(subCategory._id, subCategory.name, subCategory.bannerImage, subCategory.image)}
                        className={({ isActive }) => isActive ? 'for_w_img2' : 'for_w_img'}
                        style={{ textDecoration: 'none', color: 'black' }}>
                        <div>
                          <img src={BaseUrl + subCategory.image} alt={subCategory.name} style={{ width: '100%' }} />
                        </div>
                      </NavLink>
                    </div>
                    <div>
                      <p className='m-0 fw-400 fs-14 f-af arvo text-center'>{subCategory.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryHeader;

