import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { FaSearch, FaAngleDown } from 'react-icons/fa';
import "./header.css";
import NavbarLogo from './navbarlogo.png'
import arrowlight from './arrowrightleft.webp'
import { CategoryContext } from '../../context/CategoryContext';
import { ProductContext } from '../../context/ProductContext';
import { SearchResultContext } from '../../context/SearchResultContext'
import searc_h from './searc_h.png';
import call_th from './call_th.png';
import mail_th from './mail_th.png';
import whats_th from './whats_th.png';
import MobileNavbarLogo from './mobilelogo.webp';
import ProductBanner from './hospitality_banner.png';

import { FaAngleDown, FaBars, FaTimes } from 'react-icons/fa'; // Importing the icons

const Navbar = () => {

    const toggleSidebar = () => {
        let currentUrl = window.location.href;
        console.log(currentUrl);
        if (currentUrl != 'https://trp-tsting.vercel.app/') {
            xmarkref.current.click();
        }
    };
    const xmarkref = useRef(null);

    const [isNavOpen, setIsNavOpen] = useState(false);
    const navbarRef = useRef(null);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setIsSearchVisible(false);
        }
    };

    // Effect to add event listener when component mounts
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const searchRef = useRef(null);
    const { catId, setCatId, setCategoryName, setSubCatId, bannerImageUrl, setBannerImageUrl, setCurrentSubSubCategoryId, setSubCurrentPage } = useContext(CategoryContext);
    const { setProductId, setSubCatProductName, setProductSubcatId, setSubcategoryImage, setSubcategoryBannerImage, setSubSubCategoryId, setPage } = useContext(ProductContext);
    const { setSearchResults, setImageBannerUrl, setTotalCount, setTotalPages, setCurrentPage, setPageSize, setCounts, setSearchText } = useContext(SearchResultContext)

    const navigate = useNavigate();
    const BaseUrl = 'https://trpmanagement.s3.ap-south-1.amazonaws.com/';
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const toggleSearch = () => {
        setIsSearchVisible(prevState => !prevState);
    };

    const handleSearch = async (searchText) => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webproducts/filterProducts?key=${encodeURIComponent(searchText)}`, {
                // https://website.procureright.in/api/webproducts/filterProducts?key=Fans
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_HANDLESEARCH}`
                }
            });

            if (response.status === 200) {
                setSearchText(encodeURIComponent(searchText))
                setSearchResults(response.data.data);
                setTotalCount(response.data.totalCount);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.currentPage);
                setPageSize(response.data.pageSize);
                setCounts(response.data.counts);
                console.log(response.data);
                navigate("/searchProduct");

            }
        } catch (error) {
            console.error('Error searching products:', error);
        }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webcategories/all`, {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_HEADERCATEGORY}`
                    }
                });
                setCategories(response.data); // Assuming response.data is an array of category objects
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        const fetchSubCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webcategories/subcategorylist?categoryId=25`, {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_HEADERCATEGORY}`
                    }
                });
                setSubCategories(response.data[0].subWebCategories);
                setProductId('25')

            } catch (error) {
                console.error('Error fetching subcategories:', error);
            }
        };
        fetchCategories();
        fetchSubCategories();

    }, []);


    const handleClickOutside2 = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            setIsNavOpen(false);
        }
    };

    const closeNav = () => {
        setIsNavOpen(false);
    };

    useEffect(() => {
        if (isNavOpen) {
            document.addEventListener('mousedown', handleClickOutside2);
        } else {
            document.removeEventListener('mousedown', handleClickOutside2);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, [isNavOpen]);

    const handleCategoryClick = (id, categoryName, bannerImage) => {
        setCatId(id);
        setCategoryName(categoryName);
        setBannerImageUrl(BaseUrl + bannerImage);
        setSubCatId('')
        setCurrentSubSubCategoryId(null)
        setSubCurrentPage(1);

    };

    const handleAllProductClick = (subCatId, name, image, bannerImage) => {
        setProductSubcatId(subCatId);
        setSubCatProductName(name);
        setSubSubCategoryId(null)
        setPage(1)
        setSubcategoryImage(BaseUrl + image);
        setSubcategoryBannerImage(BaseUrl + bannerImage);
    };
    function handleSearchProductClick() {
        setSubCatProductName("Search Result")
        setSubcategoryBannerImage(ProductBanner)
    }

    return (
        <div style={{ position: 'sticky', width: '100%', top: '0px', zIndex: '10' }}>
            <div className='bg-black hide'>
                <div className='container-fluid'>
                    <div className='row  justify-content-end align-items-center m-0'>
                        <div className='col-md-12 pe-3'>
                            <div className='d-flex gap-4 pt-1 pb-1 bg-black justify-content-end text-white align-items-center'>
                                <div className='text-white m-0'>
                                    <p className='m-0 fs-12 fw-300'>For bulk orders contact us on</p>
                                </div>
                                <div className=' gap-1 d-flex align-items-center justify-content-center'>
                                    <img src={call_th} style={{ width: "12%" }} alt='img' />
                                    <img src={whats_th} style={{ width: "12%" }} alt='img' />
                                    <p className='text-white m-0 fs-12 fw-300'>+91-7011558052</p>
                                </div>
                                <div className='gap-2 d-flex align-items-center justify-content-center'>
                                    <img src={mail_th} style={{ width: "10.8%" }} alt='img' />
                                    <p className='text-white m-0 fs-12 mailto:fw-300'>procure@procureright.in</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <header>
                <div className="container-fluid p-0">
                    <nav className="navbar navbar-expand-lg navbar_shdow w-100" ref={navbarRef}>
                        <div className="row m-0 justify-content-center align-items-center w-100">
                            <div className="col-md-4 d-flex justify-content-between">
                                <Link to='/' onClick={toggleSidebar}>
                                    <img src={MobileNavbarLogo} alt="trp logo" className='show1' />
                                    <img src={NavbarLogo} alt="trp logo" style={{ width: '45%' }} className='hide' />
                                </Link>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded={isNavOpen ? "true" : "false"}
                                    aria-label="Toggle navigation"
                                    onClick={toggleNav}
                                    ref={xmarkref}
                                >
                                    {isNavOpen ? <FaTimes /> : <FaBars />}
                                </button>
                            </div>
                            <div className="col-md-8 p-0">
                                <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
                                    <div className='show1'>
                                        <div className='mobile_headers' style={{
                                            position: 'absolute',
                                            background: '#F2F2F2',
                                            height: '80vh',
                                            width: '77%',
                                            borderRadius: '20px 0px 0px 20px',
                                            left: '23%',
                                            top: '100%',
                                            boxShadow: '0px 4px 4px 0px #0000001F',
                                            padding: '15px'
                                        }}>
                                            <ul className="navbar-nav">
                                                <li className="nav-item dropdown" style={{ listStyle: "none", color: "#393939" }}>
                                                    <a className="nav-link cp arvo fw-500 fs-20" style={{ color: "#393939" }} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span className='d-flex justify-content-between w-100'>
                                                            Products <span><FaAngleDown /></span>
                                                        </span>
                                                    </a>
                                                    <ul className="dropdown-menu cp p-0" style={{ backgroundColor: 'rgb(242, 242, 242)', border: "none" }}>
                                                        {subCategories.map(subCategory => (
                                                            <li key={subCategory._id}>
                                                                <Link
                                                                    to={`/AllProductCategory/${subCategory.name}/${subCategory._id}`}
                                                                    className="dropdown-item cp fs-14 poppins fw-400" style={{ color: "#393939", padding: "5px 0px" }}
                                                                    onClick={() => {
                                                                        handleAllProductClick(subCategory._id, subCategory.name, subCategory.image, subCategory.bannerImage);
                                                                        closeNav();
                                                                    }}
                                                                >
                                                                    {subCategory.name}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                                <li className="nav-item dropdown" style={{ listStyle: "none" }}>
                                                    <a className="nav-link cp arvo fw-500 fs-20" style={{ color: "#393939" }} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span className='d-flex justify-content-between w-100'>
                                                            Sectors <span><FaAngleDown /></span>
                                                        </span>
                                                    </a>
                                                    <ul className="dropdown-menu cp p-0" style={{ backgroundColor: 'rgb(242, 242, 242)', border: "none" }}>
                                                        {categories.map(category => (
                                                            <React.Fragment key={category._id}>
                                                                {category.name !== 'All Products' && (
                                                                    <li>
                                                                        <Link
                                                                            to={`/services/${category.name}/${category._id}`}
                                                                            className="dropdown-item cp fs-14 poppins fw-400" style={{ color: "#393939", padding: "5px 0px" }}
                                                                            onClick={() => {
                                                                                handleCategoryClick(category._id, category.name, category.bannerImage);
                                                                                closeNav();
                                                                            }}
                                                                        >
                                                                            {category.name}
                                                                        </Link>
                                                                    </li>
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </ul>
                                                </li>
                                                <li className="nav-item cp" style={{ listStyle: "none" }}>
                                                    <Link to="/aboutUs" className="nav-link cp arvo fw-500 fs-20" style={{ color: "#393939" }} onClick={closeNav}>
                                                        About us
                                                    </Link>
                                                </li>
                                                <li className="nav-item cp" style={{ listStyle: "none" }}>
                                                    <Link to="/contactUs" className="nav-link cp arvo fw-500 fs-20" style={{ color: "#393939" }} onClick={closeNav}>
                                                        F.A.Q.
                                                    </Link>
                                                </li>
                                                <li className="nav-item cp" style={{ listStyle: "none" }}>
                                                    <Link to="/contactUs" className="nav-link cp arvo fw-500 fs-20" style={{ color: "#393939" }} onClick={closeNav}>
                                                        Contact us
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='hide'>
                                        <ul className="navbar-nav navbar_trp">
                                            <li className="nav-item dropdown">
                                                <a className="nav-link cp arvo fw-500 fs-13" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Products <span><FaAngleDown /></span>
                                                </a>
                                                <ul className="dropdown-menu parentsearchbar cp ul_list" style={{ backgroundColor: '#f2f2f2' }}>
                                                    {subCategories.map(subCategory => (
                                                        <li key={subCategory._id}>
                                                            <Link
                                                                to={`/AllProductCategory/${subCategory.name}/${subCategory._id}`}
                                                                className="dropdown-item"
                                                                onClick={() => {
                                                                    handleAllProductClick(subCategory._id, subCategory.name, subCategory.image, subCategory.bannerImage);
                                                                    closeNav();
                                                                }}
                                                            >
                                                                {subCategory.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link cp arvo fw-500 fs-13" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Sectors <span><FaAngleDown /></span>
                                                </a>
                                                <ul className="dropdown-menu parentsearchbar cp ul_list" style={{ backgroundColor: '#f2f2f2' }}>
                                                    {categories.map(category => (
                                                        <React.Fragment key={category._id}>
                                                            {category.name !== 'All Products' && (
                                                                <li>
                                                                    <Link
                                                                        to={`/services/${category.name}/${category._id}`}
                                                                        className="dropdown-item cp"
                                                                        onClick={() => {
                                                                            handleCategoryClick(category._id, category.name, category.bannerImage);
                                                                            closeNav();
                                                                        }}
                                                                    >
                                                                        {category.name}
                                                                    </Link>
                                                                </li>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li className="nav-item cp">
                                                <Link to="/aboutUs" className="nav-link cp arvo fw-500 fs-13" onClick={closeNav}>
                                                    About us
                                                </Link>
                                            </li>
                                            <li className="nav-item cp">
                                                <Link to="/contactUs" className="nav-link cp arvo fw-500 fs-13" onClick={closeNav}>
                                                    F.A.Q.
                                                </Link>
                                            </li>
                                            <li className="nav-item cp">
                                                <Link to="/contactUs" className="nav-link cp arvo fw-500 fs-13" onClick={closeNav}>
                                                    Contact us
                                                </Link>
                                            </li>
                                            <li className="nav-item cp hide" style={{ position: 'relative', lineHeight: "0px" }}>
                                                <div style={{ position: 'relative' }} ref={searchRef}>
                                                    <img src={searc_h} alt='Search Icon' onClick={toggleSearch} style={{ cursor: 'pointer', width: '75%' }} />
                                                    {isSearchVisible && (
                                                        <div className="parentsearchbar">
                                                            <input type="text" placeholder="Search Products & Sectors" className="input_style"
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        handleSearch(e.target.value);
                                                                        handleSearchProductClick();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </li>
                                            <li className="nav-item cp hide">
                                                <div className='navbars'>
                                                    <Link to="https://buyer.procureright.in/" target='_blank' className='td'>
                                                        <p className='mb-0 cp arvo fw-400 fs-11 ' style={{ color: '#393939' }}>Buyer Sign in</p>
                                                    </Link>
                                                    <div>
                                                        <img src={arrowlight} style={{ width: "65%" }} />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nav-item cp hide">
                                                <div className='navbars'>
                                                    <Link to="https://seller.procureright.in/" target='_blank' className='td'>
                                                        <p className='mb-0 cp arvo fw-400 fs-11' style={{ color: '#393939' }}>  Sellers Sign in</p>
                                                    </Link>
                                                    <div>
                                                        <img src={arrowlight} style={{ width: "65%" }} />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>

        </div>
    );
};

export default Navbar;
