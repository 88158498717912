
import React, { useState, useEffect, useContext, useRef } from 'react';
import AllCategoryHeader from './AllCategoryHeader';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import crpoffice from './crpoffice.png'
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { ProductContext } from '../context/ProductContext';
import { CategoryContext } from '../context/CategoryContext';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom"
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import send_en_img from './send_en_img.png';
import call_c from './call_c.png';
import mail_c from './mail_c.png';
import './allproductcategory.css';
import { Helmet } from 'react-helmet';
const AllProductCategory = () => {
  const topRef = useRef(null);
  const navigate = useNavigate();
  const productsRef = useRef(null);
  // const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [counts, setCounts] = useState(0);
  const [activesubSubCategory, setActivesubSubCategory] = useState()
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const { subCatProductName, productSubcatId, setProductSubcatId, subSubCategoryId, setSubSubCategoryId, page, setPage, setSubCatProductName } = useContext(ProductContext);
  const { setProductImageUrl, subcategoryImage, subcategoryBannerImage, setSubcategoryImage, setSubcategoryBannerImage } = useContext(ProductContext);
  const { catId, currentSubSubCategoryId, setCurrentSubSubCategoryId, subCurrentPage, setSubCurrentPage } = useContext(CategoryContext);
  const { setCatId, categoryName, setCategoryName, setSubCatId, setSubSubCatId, bannerImageUrl, setBannerImageUrl } = useContext(CategoryContext);
  // const [subSubCategoryId, setSubSubCategoryId] = useState(null); // State to track selected subSubCategoryId
  const [subSubCategories, setSubSubCategories] = useState([]);

  const baseUrl = 'https://trpmanagement.s3.ap-south-1.amazonaws.com';

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (!productSubcatId) return; // Avoid fetching if no subcategory ID is set

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webcategories/sub/${productSubcatId}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_PRODUCT}`
          }
        });
        setSubSubCategories(response.data.subSubWebCategories);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    fetchSubCategories();
  }, [productSubcatId]);

  useEffect(() => {
    if (productSubcatId && subSubCategoryId === null) {
      // Fetch products for the first subSubCategory initially if no specific subSubCategoryId is selected
      fetchInitialProducts();
    } else if (subSubCategoryId !== null) {
      // Fetch products for the selected subSubCategory
      fetchProducts(subSubCategoryId);
    }
  }, [page, productSubcatId, subSubCategoryId]);

  const fetchInitialProducts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webproducts/filterProducts?subCategoryId=${productSubcatId}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_PRODUCT}`
        }
      });
      setProducts(response.data.data);
      setTotalCount(response.data.totalCount);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      setCounts(response.data.counts);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchProducts = async (subSubCategoryId, page = 1) => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/webproducts/filterProducts?subSubCategoryId=${subSubCategoryId}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_PRODUCT}`
        }
      });
      setProducts(response.data.data);
      setTotalCount(response.data.totalCount);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      setCounts(response.data.counts);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleSubSubCategoryClick = (subSubCategoryId) => {
    setActivesubSubCategory(subSubCategoryId);
    setSubSubCategoryId(subSubCategoryId); // Set selected subSubCategoryId
    setPage(1); // Reset page to 1 when switching sub-subcategory
  };
  useEffect(() => {
    // Scroll to the top whenever the page or currentPage changes
    if (productsRef.current) {
      productsRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [page, currentPage]);
  useEffect(() => {
    // Scroll to the top whenever the page or currentPage changes
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [page, currentPage]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productSubcatId]);
  const handProductClick = () => {
    let navproid = '25.1';
    let probanner = "HAVC"
    setProductSubcatId(navproid);
    setSubSubCategoryId(null)
    setPage(1)
    setSubCatProductName(probanner);
    setSubcategoryBannerImage(crpoffice);
    navigate(`/AllProductCategory/${probanner}/${navproid}`);
  };
  const handSectorClick = () => {
    let navid = 36;
    let navname = "Hospitality"
    setCatId(36);
    setCategoryName("Hospitality");
    setBannerImageUrl(crpoffice);
    setSubCatId('');
    setSubSubCatId('');
    setCurrentSubSubCategoryId(null)
    setSubCurrentPage(1)
    navigate(`/services/${navname}/${navid}`);
  };
  const handAllClick = () => {
    let navproid = '25.1';
    let probanner = "HAVC"
    setProductSubcatId(navproid);
    setSubSubCategoryId(null)
    setPage(1)
    setSubCatProductName(probanner);
    setSubcategoryBannerImage(crpoffice);
    navigate(`/AllProductCategory/${probanner}/${navproid}`);
  };
  return (
    <div>
      <Helmet>
        <title>TRP Managment Products</title>
        <meta name="description" content="Welcome to My Website. Explore our services and products." />
        <meta name="keywords" content="home, website, services, products" />
      </Helmet>
      <AllCategoryHeader />
      <div ref={topRef}></div>
      <div className='container'>
        <div className='row pt-5 pb-4'>
          <div className='fixed_mobile_model show1'>
            <div className='container'>
              <div className='model_fidex'>
                <div>
                  <div className='text-center' onClick={(id) => { handSectorClick() }}>
                    <img src="/sectors.png"
                      alt="Sectors" />
                    <p className='mb-0 fs-16 arvo'>Sectors</p>
                  </div>
                </div>
                <div>
                  <div className='text-center'>
                    <div className='main_circle_mobile'>
                      <div className='circle_mobile'>
                        <img
                          src="/application.png"
                          alt="All"
                          onClick={handAllClick}
                        />
                      </div>
                    </div>
                    <div>
                      <p className='mb-0 fs-16 arvo all_text_mobile' onClick={handAllClick}>All</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='text-center' > <img s src="/products.png"
                    alt="Products"
                    onClick={handProductClick} />

                    <p className='mb-0 fs-16 arvo' onClick={handProductClick}>Products</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='new_aco_c hide'>
              <Accordion className='my_accordion' defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>{subCatProductName}</Accordion.Header>
                  <Accordion.Body>
                    <ul className='electri'>
                      {subSubCategories.map((subSubCategory) => (
                        <li
                          key={subSubCategory._id}
                          onClick={() => handleSubSubCategoryClick(subSubCategory._id)}
                          className={subSubCategory._id === activesubSubCategory ? 'active' : ''}
                          style={{ cursor: 'pointer' }}
                        >
                          {subSubCategory.name}
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className='col-md-9'>
            <div className='for_heigh_s' ref={productsRef}>
              <div className='row'>
                {products.map((product) => (
                  <div className='col-6 col-md-3 col-lg-3 pb-5' key={product._id}>
                    <div className='d-flex flex-column align-items-center'>
                      <div style={{ width: "100%", height: "180px" }}>
                        <img
                          src={`${baseUrl}/${product.images[0]}`}
                          alt={product.product_name}
                          style={{ width: '100%', height: "100%", objectFit: 'cover' }}
                        />
                      </div>
                      <div className='text-center'>
                        <p className='m-0 pt-3 pb-3 Poppins fw-bold heigt' style={{ color: "#393939" }}>{product.product_name}</p>
                        <button className='enquiry' onClick={handleShow}>Send Enquiry</button>
                      </div>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Body className='p-0'>
                          <div className='row m-0'>
                            <div className='col-md-6 p-0'>
                              <img src={send_en_img} className='send_en' alt='img' />
                            </div>
                            <div className='col-md-6 padding_descktops'>
                              <Modal.Header closeButton></Modal.Header>
                              <p className='p_send_e'>Please Send Your Enquiries Via:</p>
                              <div className='row mt-3 hide'>
                                <div className='col-md-2'>
                                  <img src={call_c} style={{ height: '50px' }} alt='img' />
                                </div>
                                <div className='col-md-10'>
                                  <p className='ph_ma_s'>Phone Number</p>
                                  <p className='poppins fs-20'>+91-7011558052</p>
                                </div>
                              </div>
                              <div className='row mb-3 hide'>
                                <div className='col-md-2'>
                                  <img src={mail_c} style={{ height: '50px' }} alt='img' />
                                </div>
                                <div className='col-md-10'>
                                  <p className='ph_ma_s'>Email Id</p>
                                  <p className='poppins fs-20'>procure@procureright.in</p>
                                </div>
                              </div>

                              <div className='show1'>
                                <div className='d-flex mt-3 gap-3'>
                                  <div className=''>
                                    <img src={call_c} style={{ height: '30px' }} alt='img' />
                                  </div>
                                  <div className=''>
                                    <p className='ph_ma_s'>Phone Number</p>
                                    <p className='poppins fs-12'>+91-7011558052</p>
                                  </div>
                                </div>
                              </div>
                              <div className='show1'>
                                <div className='d-flex mb-3 gap-3'>
                                  <div className=''>
                                    <img src={mail_c} style={{ height: '30px' }} alt='img' />
                                  </div>
                                  <div className=''>
                                    <p className='ph_ma_s'>Email Id</p>
                                    <p className='poppins fs-12'>procure@procureright.in</p>
                                  </div>
                                </div>
                              </div>

                              <p className='text-center arvo fs-16 fw-400 gray_text m-0 mobile_textssss'>Our team is here to assist you with any questions or concerns you may have. Feel free to reach out to us using the contact details provided above.</p>
                              <p className='text-center arvo fs-16 fw-400 gray_text m-0 mobile_textssss'>or</p>
                              <p className='text-center arvo fs-16 fw-400 gray_text m-0 mobile_textssss'>Click below to access our buyer portal</p>
                              <div className='d-flex justify-content-center mt-3 mobile_paddings2'>
                                <Link to="https://buyer.procureright.in/">
                                  <button className='bulk_buy arvo'>Click here for bulk Buy</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className='container'>
        <div className='row m-0 justify-content-center pb-3'>
          <div className='col-10 col-md-12 position-relative mobile_responsiver_pagination'>
            {/* Conditional rendering based on whether subSubCategoryId is set */}
            {subSubCategoryId !== null ? (
              <PaginationControl
                page={currentPage}
                between={4}
                total={totalPages}
                limit={1}
                changePage={(page) => {
                  setCurrentPage(page);
                  fetchProducts(subSubCategoryId, page); // Fetch products for the selected subSubCategory
                }}
                ellipsis={1} />
            ) : (
              <PaginationControl
                page={page}
                between={4}
                total={totalPages}
                limit={1}
                changePage={(page) => {
                  setPage(page);
                }}
                ellipsis={1}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProductCategory;
