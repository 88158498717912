// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 576px) {
  .hide {
    display: none;
  }

  .show1 {
    display: block;
  }

  .list_style {
    font-size: 11px;
    font-weight: 400;
    list-style: none;
    line-height: 30px;
    text-decoration: none;
    color: #393939;
    font-family: 'Poppins';
  }

  .footer_padding2 {
    padding: 0px 0px !important;
    font-size: 16px !important;
  }

  .padgn_mobile {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .socila_icons2 {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .fotter_contact_data_mobile {
    font-size: 13px;
    font-weight: 400;
    color: #393939;
    font-family: 'Poppins';
  }
}`, "",{"version":3,"sources":["webpack://./src/common/footer/mobilefooterresponsive.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;IACd,sBAAsB;EACxB;;EAEA;IACE,2BAA2B;IAC3B,0BAA0B;EAC5B;;EAEA;IACE,4BAA4B;IAC5B,+BAA+B;EACjC;;EAEA;IACE,aAAa;IACb,SAAS;IACT,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,sBAAsB;EACxB;AACF","sourcesContent":["@media screen and (max-width: 576px) {\n  .hide {\n    display: none;\n  }\n\n  .show1 {\n    display: block;\n  }\n\n  .list_style {\n    font-size: 11px;\n    font-weight: 400;\n    list-style: none;\n    line-height: 30px;\n    text-decoration: none;\n    color: #393939;\n    font-family: 'Poppins';\n  }\n\n  .footer_padding2 {\n    padding: 0px 0px !important;\n    font-size: 16px !important;\n  }\n\n  .padgn_mobile {\n    padding-top: 20px !important;\n    padding-bottom: 20px !important;\n  }\n\n  .socila_icons2 {\n    display: flex;\n    gap: 10px;\n    justify-content: center;\n  }\n\n  .fotter_contact_data_mobile {\n    font-size: 13px;\n    font-weight: 400;\n    color: #393939;\n    font-family: 'Poppins';\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
