import React, { createContext, useState, useEffect } from "react";

const ProductContext = createContext();

const ProductContextProvider = (props) => {
  const [productId, setProductId] = useState(() => localStorage.getItem('productId') || '');
  const [productSubcatId, setProductSubcatId] = useState(() => localStorage.getItem('productSubcatId') || '');
  const [productSubSubcatId, setProductSubSubcatId] = useState(() => localStorage.getItem('productSubSubcatId') || '');
  const [productImageUrl, setProductImageUrl] = useState(() => localStorage.getItem('productImageUrl') || '');
  const [subCatProductName, setSubCatProductName] = useState(() => localStorage.getItem('subCatProductName') || '');
  const [subCatBanner, setSubCatBanner] = useState(() => localStorage.getItem('subCatBanner') || '');
  const [subcategoryImage, setSubcategoryImage] = useState(() => localStorage.getItem('subcategoryImage') || '');
  const [subcategoryBannerImage, setSubcategoryBannerImage] = useState(() => localStorage.getItem('subcategoryBannerImage') || '');
  const [subSubCategoryId, setSubSubCategoryId] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    localStorage.setItem('productId', productId);
  }, [productId]);

  useEffect(() => {
    localStorage.setItem('productSubcatId', productSubcatId);
  }, [productSubcatId]);

  useEffect(() => {
    localStorage.setItem('productSubSubcatId', productSubSubcatId);
  }, [productSubSubcatId]);

  useEffect(() => {
    localStorage.setItem('productImageUrl', productImageUrl);
  }, [productImageUrl]);

  useEffect(() => {
    localStorage.setItem('subCatProductName', subCatProductName);
  }, [subCatProductName]);

  useEffect(() => {
    localStorage.setItem('subCatBanner', subCatBanner);
  }, [subCatBanner]);

  useEffect(() => {
    localStorage.setItem('subcategoryImage', subcategoryImage);
  }, [subcategoryImage]);

  useEffect(() => {
    localStorage.setItem('subcategoryBannerImage', subcategoryBannerImage);
  }, [subcategoryBannerImage]);

  return (
    <ProductContext.Provider value={{
      productId, setProductId,
      productSubcatId, setProductSubcatId,
      productSubSubcatId, setProductSubSubcatId,
      productImageUrl, setProductImageUrl,
      subCatProductName, setSubCatProductName,
      subCatBanner, setSubCatBanner,
      subcategoryImage, setSubcategoryImage,
      subcategoryBannerImage, setSubcategoryBannerImage,
      subSubCategoryId, setSubSubCategoryId,
      page, setPage
    }}>
      {props.children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductContextProvider };


// setSubcategoryImage(BaseUrl + image);
// setSubcategoryBannerImage(BaseUrl + bannerImage);