import React, { useState, useEffect } from 'react';
import Footer from '../../common/footer/Footer'
import Header from '../../common/header/Header'
import "./about.css";
import "./mobileaboutresponsive.css";
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>TRP Sustainable Construction Solutions</title>
        <meta name="description" content="We empower buyers and sellers with streamlined procurement solutions, leveraging a vast network of trusted suppliers and meticulous negotiation practices to ensure top-quality materials." />
        <meta name="keywords" content="extensive inventory, network of suppliers, top-quality materials, streamlined procurement solutions" />
      </Helmet>
      <div>
        <div className='container-fluid '>
          <div className='row'>
            <div className='col-md-12 p-0 position-relative'>
              <img src="./aboutbgimage.webp" style={{ width: '100%' }} alt="Water" className='contact_mobile_img' />
              <nav aria-label="breadcrumb" className='for_poss' style={{ position: "absolute", top: "24px", left: "60px", zIndex: "1" }}>
                <ol class="breadcrumb text-white ps-1">
                  <li className="text-white">
                    <Link to="/" className="text-white fs-14" style={{ textDecoration: "none" }}>
                      Home
                    </Link>
                    <i className="fa-solid fa-greater-than fs-9 ps-2 pe-2"></i>
                  </li>
                  <li class="breadcrumb-item text-white"><a href="#" className='text-white fs-14' style={{ textDecoration: "none" }}>About us</a></li>
                </ol>
              </nav>
              <h2 className='for_f'>About us</h2>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className="row m-0 justify-content-center pt-5">
            <p className="text-center fs-18 fw-400 poppins arvo-regular2 margin_mobile">WHY YOU NEED TRP</p>
            <div className="col-md-12 text-center fs-22 fw-500 pb-5 p-0">
              <h2 className="text-center fs-30 fw-400 arvo para_mobile3">
                Commonly faced <span className="text-orange fs-30 fw-700 arvo para_mobile3"> problems by buyers </span>during procurement
              </h2>
            </div>
          </div>
          <div className='row m-0 justify-content-center pb-5'>
            <div className='col-md-4 text-center d-flex justify-content-center flex-column align-items-center'>
              <img src='./aboutservice2.webp' alt='aboutservice1' style={{ width: "65%" }} className='about_imge' />
              <div >
                <div className='d-flex justify-content-center align-items-center flex-column' >
                  <h2 className=' fs-20 fw-700 arvo pt-4 pb-3 mb-0 arvo-regular3' style={{ color: "#393939" }}>Complex & Chaotic</h2>
                  <p className='fs-16 fw-400 poppins text-center text_align_mobile' style={{ width: "79%" }}>Contacting over a Thousand Manufacturers, Distributors & Dealers for the various items needed.</p>

                </div>
              </div>
            </div>
            <div className='col-md-4 text-center d-flex justify-content-center flex-column align-items-center padgn_mobile'>
              <img src='./aboutservice1.webp' alt='aboutservice1' style={{ width: "65%" }} className='about_imge' />
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <h2 className=' fs-20 fw-700 arvo pt-4 pb-3 mb-0 arvo-regular3' style={{ color: "#393939" }}>Time Consuming & Inefficient</h2>
                <p className='fs-16 fw-400 poppins text-center text_align_mobile' style={{ width: "78%" }}>Understanding Material Specifications & Discussing different terms and conditions.</p>
              </div>
            </div>
            <div className='pb-4 col-md-4 text-center d-flex justify-content-center flex-column align-items-center padgn_mobile'>
              <img src='./aboutservice3.webp' alt='aboutservice1' style={{ width: "65%" }} className='about_imge' />
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <h2 className=' fs-20 fw-700 arvo pt-4 pb-3 mb-0 arvo-regular3' style={{ color: "#393939" }}>Costly & Confusing</h2>
                <p className='fs-16 fw-400 poppins text-center text_align_mobile' style={{ width: "66%" }}>Getting Fair Prices due to multi layered pricing.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='parent_div'>
          <div className='container'>
            <div className="row m-0 justify-content-center">
              <p className="text-center fs-18 fw-400 poppins arvo-regular2 margin_mobile">COMPANY OVERVIEW</p>
              <h2 className="text-center fs-30 fw-400 arvo pb-3 para_mobile3">
                Empowering <span className="text-orange fs-30 fw-700 arvo para_mobile3">Procurement Excellence</span> with TRP
              </h2>
              <div className="col-md-11 text-center d-flex justify-content-center align-items-center p-0">
                <p className="poppins mb-0 fs-16 fw-400 text_fs13_mobile">
                  At TRP, we specialize in empowering both buyers and sellers with streamlined procurement solutions. With a vast network of trusted suppliers and meticulous negotiation practices, we ensure that buyers access top-quality materials at competitive prices.</p>
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='row m-0 justify-content-center align-items-center pt-5 hide'>
              <div className='col-md-6'>
                <div className='first_div'>
                  <div className='row m-0 '>
                    <div className='col-md-2 d-flex justify-content-center p-0'>
                      <div className='bacKcolorimg'>
                        <img src="/desc_seller.png" alt='aboutuser' style={{ width: "55%" }} />
                      </div>
                    </div>
                    <div className='col-md-10 d-flex align-items-center'>
                      <p className="fs-14 fw-400 poppins mb-0">Our buyer-centric approach simplifies the procurement process, allowing clients to focus on their core business activities while tracking progress effortlessly.</p></div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='first_div'>
                  <div className='row m-0 '>
                    <div className='col-md-2 d-flex justify-content-center p-0'>
                      <div className='bacKcolorimg'>
                        <img src="/desc_buyer.png" alt='aboutuser' style={{ width: "55%" }} />
                      </div>
                    </div>
                    <div className='col-md-10 d-flex align-items-center'>
                      <p className="fs-14 fw-400 poppins mb-0">For sellers, TRP offers a platform to showcase products to a wide audience, facilitating business growth and enhancing market visibility.</p></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row m-0 justify-content-center align-items-center pt-4 show1'>
              <div className='col-md-12'>
                <div className='row m-0'>
                  <div className='first_div d-flex' style={{ gap: "10px" }}>
                    <div className='col-md-2'>
                      <div className='bacKcolorimg'>
                        <img src="/desc_seller.png" alt='aboutuser' style={{ width: "55%" }} />
                      </div>
                    </div>
                    <div className='col-md-10'>
                      <p className="fs-14 fw-400 poppins mb-0 about_text_mobile">Our buyer-centric approach simplifies the procurement process, allowing clients to focus on their core business activities while tracking progress effortlessly.</p>
                    </div>
                  </div>
                </div>
                <div className='row m-0 pt-3'>
                  <div className='first_div d-flex' style={{ gap: "10px" }}>
                    <div className='col-md-2'>
                      <div className='bacKcolorimg'>
                        <img src="/desc_buyer.png" alt='aboutuser' style={{ width: "55%" }} />
                      </div>
                    </div>
                    <div className='col-md-10'>
                      <p className="fs-14 fw-400 poppins mb-0 about_text_mobile">For sellers, TRP offers a platform to showcase products to a wide audience, facilitating business growth and enhancing market visibility.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row m-0 pt-5 pb-5'>
              <div className='col-md-12'>
                <img src="./abouttabimage.webp" alt='abouttabimage' style={{ width: "100%", height: "100vh" }} className='about_mobile_img' />
              </div>
              {/* <div className='col-md-4'>
                <p>Pendign from client</p>
              </div> */}
            </div>
          </div>
        </div>

        <div style={{ background: "#F2F2F2" }}>
          <div className='container hide'>
            <div className='row m-0 justify-content-center align-items-center pt-5 pb-5'>
              <div className='col-md-4'>
                <div className='about_mains'>
                  <div className='row m-0 flex-column justify-content-center align-items-center gap-4'>
                    {/* <div className='col-md-4'> */}
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage1.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Effective Communication</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage2.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Proven Track Record</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage3.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Strategic Approach</h3>
                      </div>
                    </div>
                  </div>
                  <div className='row m-0 flex-column justify-content-center align-items-center gap-4'>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage4.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Deep Industry Knowledge</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage5.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Specialized Skills</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4 text-center d-flex justify-content-center flex-column align-items-center pt-4'>
                <img src="./aboutgroupimage.webp" alt='aboutgroupimage' style={{ width: "80%" }} />
                <p className='fs-25 fw-400 arvo text-center pt-4' style={{ lineHeight: "1.3", maxWidth: '336px' }}>The TRP team has over 150 years of combined experience in construction. This allows them to efficiently solve complex procurement problems for a wide range of clients.</p>
              </div>
              <div className='col-md-4'>
                <div className='about_mains'>
                  <div className='row m-0 flex-column justify-content-center align-items-center gap-4'>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage6.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Problem-Solving Abilities</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage7.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Attention to Detail</h3>
                      </div>
                    </div>
                  </div>
                  <div className='row m-0 flex-column justify-content-center align-items-center gap-4'>
                    {/* <div className='col-md-4'> */}
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage8.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Customer Focus</h3>
                      </div>
                    </div>
                    <div className='about_div2'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage9.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Adaptability and Flexibility</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage10.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Continuous Improvement</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container show1'>
            <div className='row m-0 justify-content-center align-items-center pt-5 pb-5'>
              <div className='col-md-12 text-center d-flex justify-content-center flex-column align-items-center pt-4 about_trp_img_mobile'>
                <img src="./aboutgroupimage.webp" alt='aboutgroupimage' style={{ width: "80%" }} className='mobile_trpImg' />
                <p className='fs-25 fw-400 arvo text-center pt-4 arvo-regular2' style={{ lineHeight: "1.3", maxWidth: '336px' }}>The TRP team has over 150 years of combined experience in construction. This allows them to efficiently solve complex procurement problems for a wide range of clients.</p>
              </div>
              <div className='col-md-12 padgn_mobile p-0'>
                <div className='about_mains'>
                  <div className='row m-0 flex-column justify-content-center align-items-center gap-4'>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage1.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Effective Communication</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage2.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Proven Track Record</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage3.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Strategic Approach</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage8.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Customer Focus</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage7.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Attention to Detail</h3>
                      </div>
                    </div>
                  </div>
                  <div className='row m-0 flex-column justify-content-center align-items-center gap-4'>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage4.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Deep Industry Knowledge</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage5.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Specialized Skills</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage6.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Problem-Solving Abilities</h3>
                      </div>
                    </div>
                    <div className='about_div2'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage9.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Adaptability and Flexibility</h3>
                      </div>
                    </div>
                    <div className='about_div'>
                      <div>
                        <div className='d-flex justify-content-center p-0'>
                          <div className='image_background_clr'>
                            <img src="./aboutimage10.webp" alt='aboutuser' style={{ width: "65%" }} />
                          </div>
                        </div>
                        <h3 className="fs-14 fw-400 arvo pt-2">Continuous Improvement</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
